import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FactoryView } from 'src/app/models/factoryViewModel.model';
import { FunctionGroup } from 'src/app/models/functionGroup.model';
import { ProductionLineView } from 'src/app/models/productionLineView.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-administrate-list-functions',
  templateUrl: './administrate-list-functions.component.html',
  styleUrl: './administrate-list-functions.component.less'
})
export class AdministrateListFunctionsComponent {

  apiUrl = environment.API_URL;

  selectedFactoryId:number | null = null;
  selectedLineId:number | null = null;
  authorizedFactories:FactoryView[] = [];
  authorizedLines:ProductionLineView[] = [];
  linkedLines:Set<number> = new Set();
  
  functionsList:FunctionGroup[] = [];
  functionsLoaded = false;

  hasFunctionToChange = false;
  selectedFunction:FunctionGroup | null = null;
  functionToModify:FunctionGroup | null = null;
  selectedParentId:number | null = null;
  isSavingData = false;

  constructor(
    private httpClient:HttpClient,
    private translate: TranslateService) {
  }

  ngOnInit() {
    this.getAuthorizedFactories(2);
  }

  selectFactory() {
    this.authorizedLines = this.authorizedFactories.find(x=>x.id === this.selectedFactoryId)?.productionLines || [];
    if (this.authorizedLines.length > 0) {
      this.selectedLineId = this.authorizedLines[0].id ?? null;
    } else {
      this.selectedLineId = null;
    }
    this.getFunctionsList(this.selectedLineId);
  }

  selectLine() {
    this.getFunctionsList(this.selectedLineId);
  }

  getAuthorizedFactories(page?:number) {
    this.httpClient.get<FactoryView[]>(`${this.apiUrl}Factory/ByPageType/${page}`).subscribe(
      {
        next: (res) => {
          this.authorizedFactories = res;
          this.selectedFactoryId = res[0].id ?? null;
          this.selectFactory();
        }
      }
    );
  }

  getFunctionsList(plId:number | null) {
    this.functionsLoaded = false;
    
    if(plId == null || localStorage.getItem('language') == null) {
      this.selectedFunction = null;
      this.functionsList = [];
      return;
    }
    
    this.isSavingData = true;
    this.httpClient.get<FunctionGroup[]>(`${this.apiUrl}FunctionGroup/ByProductionLineIdFull/${plId}`).subscribe(
      {
        next: (res) => {
          this.functionsLoaded = true;
          this.functionsList = res;
          if (this.functionsList.find(x=>x.id === this.selectedFunction?.id) == null) {
            this.selectedFunction = null;
          }
          this.isSavingData = false;
        },
        error: (e) => {
          this.functionsList = [];
          this.isSavingData = false;
        }
      }
    );
  }

  toggleEnabled(fgId:number) {
    const fg = this.functionsList.find(x=>x.id === fgId);
    if(fg == null) { return; }

    this.isSavingData = true;
    const method = fg.disabled ? "Enable" : "Disable";
    this.httpClient.put<FunctionGroup[]>(`${this.apiUrl}FunctionGroup/${method}/${fgId}/${this.selectedLineId!}`, null).subscribe(
      {
        next: (res) => {
          this.functionsLoaded = true;
          this.functionsList = res;
          this.isSavingData = false;
        },
        error: (e) => {
          this.isSavingData = false;
        }
      }
    );
  }

  selectFunction(fgId: number) {
    const fg = this.functionsList.find(x=>x.id === fgId);
    if(fg == null) { return; }
    this.selectedFunction = fg;
    this.loadFunctionLines(fgId);
  }

  loadFunctionLines(fgId: number) {
    this.isSavingData = true;
    this.linkedLines.clear();
    this.httpClient.get<number[]>(`${this.apiUrl}FunctionGroup/${fgId}/ProductionLines`).subscribe(
      {
        next: (res) => {
          res.forEach(line => {
            this.linkedLines.add(line);
          });
          this.isSavingData = false;
        },
        error: (e) => {
          this.isSavingData = false;
        }
      }
    );
  }

  openAddFunctionPopup(fgId: number | null) {
    if (this.selectedLineId == null) { return; }

    this.hasFunctionToChange = true;

    this.selectedParentId = fgId;
    this.functionToModify = null;
  }

  openEditFunctionPopup(fgId:number) {
    const fg = this.functionsList.find(x=>x.id === fgId);
    if(fg == null) { return; }

    this.hasFunctionToChange = true;

    this.functionToModify = fg;
  }

  closeFunctionPopup(functionGroup: FunctionGroup | null) {
    this.hasFunctionToChange = false;
    this.functionToModify = null;
    this.selectedParentId = null;
    if (functionGroup != null) {
      const fgIdx = this.functionsList.findIndex(x=>x.id === functionGroup.id);
      if (fgIdx !== -1) {
        this.functionsList.splice(fgIdx, 1, functionGroup);
      } else {
        this.functionsList.push(functionGroup);
      }
      this.functionsList = [...this.functionsList];
      this.selectFunction(functionGroup.id!);
    }
  }

  onCheckChange(lineId: number, event: Event) {
    if (this.selectedFunction === null) { return; }

    const target = event.target as HTMLInputElement;
    this.isSavingData = true;
    target.checked = !target.checked; // Checked state will be determined by the contents of linkedLines after the request

    if (!target.checked) {
      this.httpClient.post<any>(`${this.apiUrl}FunctionGroup/${this.selectedFunction.id}/ProductionLine/${lineId}`, null).subscribe(
        {
          next: (res) => {
            this.isSavingData = false;
            this.linkedLines.add(lineId);
          },
          error: (e) => {
            this.isSavingData = false;
          }
        }
      );
    } else {
      this.httpClient.delete<any>(`${this.apiUrl}FunctionGroup/${this.selectedFunction.id}/ProductionLine/${lineId}`).subscribe(
        {
          next: (res) => {
            this.isSavingData = false;
            this.linkedLines.delete(lineId);
          },
          error: (e) => {
            this.isSavingData = false;
          }
        }
      );
    }
  }

  areAllLinesChecked(factoryId: number) {
    const factory = this.authorizedFactories.find(x=>x.id === factoryId);

    for (const line of factory!.productionLines) {
      if (!this.linkedLines.has(line.id!)) {
        return false;
      }
    }

    return true;
  }

  onCheckAllLinesChange(factoryId: number, event: Event) {
    if (this.selectedFunction === null) { return; }

    const target = event.target as HTMLInputElement;
    this.isSavingData = true;
    target.checked = !target.checked; // Checked state will be determined by the contents of linkedLines after the request

    if (!target.checked) {
      this.httpClient.post<any>(`${this.apiUrl}FunctionGroup/${this.selectedFunction.id}/Factory/${factoryId}`, null).subscribe(
        {
          next: (res) => {
            const factory = this.authorizedFactories.find(x=>x.id === factoryId);
        
            for (const line of factory!.productionLines) {
              this.linkedLines.add(line.id!);
            }
            this.isSavingData = false;
          },
          error: (e) => {
            this.isSavingData = false;
          }
        }
      );
    } else {
      this.httpClient.delete<any>(`${this.apiUrl}FunctionGroup/${this.selectedFunction.id}/Factory/${factoryId}`).subscribe(
        {
          next: (res) => {
            const factory = this.authorizedFactories.find(x=>x.id === factoryId);
        
            for (const line of factory!.productionLines) {
              this.linkedLines.delete(line.id!);
            }
            this.isSavingData = false;
          },
          error: (e) => {
            this.isSavingData = false;
          }
        }
      );
    }
  }
}
