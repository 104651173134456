import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { msalConfig, loginRequest, protectedResources } from './auth-config';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { ProductionLineSettingsPageComponent } from './pages/production-line-settings-page/production-line-settings-page.component';
import { DirectionArrowComponent } from './components/direction-arrow/direction-arrow.component';
import { DemoButtonComponent } from './components/demo-button/demo-button.component';
import { RolesRightsSettingsPageComponent } from './pages/roles-rights-settings-page/roles-rights-settings-page.component';
import { UsersRolesSettingsPageComponent } from './pages/users-roles-settings-page/users-roles-settings-page.component';
import { ErrorPageMessageComponent } from './error-page-message/error-page-message.component';
import { HealthCheckComponent } from './components/health-check/health-check.component';
import { ErrorHandlerService } from './error-handler.service';
import { MonitoringService } from './monitoring.service';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { SuperAdminPageComponent } from './pages/super-admin-page/super-admin-page.component';
import { AdministrateListFunctionsComponent } from './pages/administrate-lists/administrate-list-functions/administrate-list-functions.component';
import { TreeDataComponent } from './components/tree-data/tree-data.component';
import { TreeElementPopupComponent } from './components/tree-element-popup/tree-element-popup.component';
import { MatTreeModule } from '@angular/material/tree';
import { AdministrateListTagsComponent } from './pages/administrate-lists/administrate-list-tags/administrate-list-tags.component';
import { AdministrateListAgentsComponent } from './pages/administrate-lists/administrate-list-agents/administrate-list-agents.component';

//Azure Connection
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map < string,
      Array < string >> ();
  protectedResourceMap.set(protectedResources.demoApi.endpoint, protectedResources.demoApi.scopes);
  return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap
  };
}
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest
};
}

//Data Back Connection
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ProductionLineSettingsPageComponent,
    DirectionArrowComponent,
    DemoButtonComponent,
    RolesRightsSettingsPageComponent,
    UsersRolesSettingsPageComponent,
    ErrorPageMessageComponent,
    HealthCheckComponent,
    ErrorPageComponent,
    SuperAdminPageComponent,
    AdministrateListAgentsComponent,
    AdministrateListFunctionsComponent,
    AdministrateListTagsComponent,
    TreeDataComponent,
    TreeElementPopupComponent,
  ],
  imports: [
    BrowserModule,
    MsalModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    MatSelectModule,
    MatIconModule,
    ReactiveFormsModule,
    FormsModule,
    DragDropModule,
    MatTreeModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
  ],
  providers: [{
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
  }, {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
  }, {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
  }, {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
  }, { 
    provide: ErrorHandler, 
    useClass: ErrorHandlerService 
  },
  MonitoringService,
  MsalService,
  MsalGuard,
  MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
