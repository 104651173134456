import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FactoryView } from 'src/app/models/factoryViewModel.model';
import { FaultType } from 'src/app/models/faultType.model';
import { Language } from 'src/app/models/language.enum';
import { ProductionLineView } from 'src/app/models/productionLineView.model';
import { Responsible } from 'src/app/models/responsible.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-administrate-list-responsibles',
  templateUrl: './administrate-list-responsibles.component.html',
  styleUrl: './administrate-list-responsibles.component.less'
})
export class AdministrateListResponsiblesComponent {

  apiUrl = environment.API_URL;
  
  selectedFactoryId: number | null = null;
  selectedLineId: number | null = null;
  selectedFaultTypeId: number | null = null;
  authorizedFactories: FactoryView[] = [];
  authorizedLines: ProductionLineView[] = [];
  linkedLines: Set<number> = new Set();
    
  faultTypesList: FaultType[] = [];
  responsiblesList: Responsible[] = [];
  
  hasResponsibleToChange = false;
  selectedResponsible: Responsible | null = null;
  responsibleToModify: Responsible | null = null;
  selectedParentId: number | null = null;
  isSavingData = false;
  
  constructor(
    private httpClient: HttpClient,
    private translate: TranslateService) {
  }

  ngOnInit() {
    this.getAuthorizedFactories(2);
  }

  //LANGUAGE SETTING
  getFaultTypeRank2Name(faultType: FaultType | null){
    if (faultType == null) { return ""; }
    
    const languageMapping: { [key: string]: string } = {
      "fr": faultType.rank2NameFR || "",
      "nl": faultType.rank2NameNL || "",
      "en": faultType.rank2NameEN || ""
    };

    return languageMapping[this.translate.currentLang] || "";
  }
  
  selectFactory() {
    this.authorizedLines = this.authorizedFactories.find(x=>x.id === this.selectedFactoryId)?.productionLines || [];
    if (this.authorizedLines.length > 0) {
      this.selectedLineId = this.authorizedLines[0].id ?? null;
    } else {
      this.selectedLineId = null;
    }
    this.selectLine();
  }

  selectLine() {
    this.getFaultTypesList(this.selectedLineId);
  }

  selectFaultType() {
    this.getResponsiblesList(this.selectedLineId, this.selectedFaultTypeId);
  }

  getAuthorizedFactories(page?:number) {
    this.httpClient.get<FactoryView[]>(`${this.apiUrl}Factory/ByPageType/${page}`).subscribe(
      {
        next: (res) => {
          this.authorizedFactories = res;
          this.selectedFactoryId = res[0].id ?? null;
          this.selectFactory();
        }
      }
    );
  }

  getFaultTypesList(plId:number | null) {
    this.faultTypesList = [];
    
    if(plId == null || localStorage.getItem('language') == null) {
      this.selectedFaultTypeId = null;
      this.selectFaultType();
      return;
    }
    
    this.isSavingData = true;
    this.httpClient.get<FaultType[]>(`${this.apiUrl}FaultType/ByProductionLineId/${plId}`).subscribe(
      {
        next: (res) => {
          this.faultTypesList = res;
          if (this.faultTypesList.find(x => x.id === this.selectedFaultTypeId) == null) {
            this.selectedFaultTypeId = -1;
          }
          this.isSavingData = false;
          this.selectFaultType();
        },
        error: (e) => {
          this.isSavingData = false;
        }
      }
    );
  }

  getResponsiblesList(plId:number | null, ftId:number | null) {
    if(plId == null || localStorage.getItem('language') == null) {
      this.selectedResponsible = null;
      this.responsiblesList = [];
      return;
    }
    
    this.isSavingData = true;
    this.httpClient.get<Responsible[]>(`${this.apiUrl}Responsible/ByProductionLineAndFaultTypeFull/${plId}/${ftId}`).subscribe(
      {
        next: (res) => {
          this.responsiblesList = res;
          if (this.responsiblesList.find(x => x.id === this.selectedResponsible?.id) == null) {
            this.selectedResponsible = null;
          }
          this.isSavingData = false;
        },
        error: (e) => {
          this.responsiblesList = [];
          this.isSavingData = false;
        }
      }
    );
  }
  
  toggleEnabled(responsibleId:number) {
    const responsible = this.responsiblesList.find(x => x.id === responsibleId);
    if (responsible == null || this.selectedLineId === null || this.selectedFaultTypeId === null) { return; }

    this.isSavingData = true;
    const method = responsible.disabled ? "Enable" : "Disable";
    this.httpClient.put<Responsible[]>(`${this.apiUrl}Responsible/${method}/${responsibleId}/${this.selectedLineId}/${this.selectedFaultTypeId}`, null).subscribe(
      {
        next: (res) => {
          this.responsiblesList = res;
          this.isSavingData = false;
        },
        error: (e) => {
          this.isSavingData = false;
        }
      }
    );
  }

  selectResponsible(responsibleId: number) {
    const responsible = this.responsiblesList.find(x => x.id === responsibleId);
    if(responsible == null) { return; }
    this.selectedResponsible = responsible;
    // this.loadResponsibleLines(fgId); // TODO
  }
  
  loadResponsibleLines(responsibleId: number) {
    this.isSavingData = true;
    this.linkedLines.clear();
    this.httpClient.get<number[]>(`${this.apiUrl}Responsible/${responsibleId}/ProductionLines`).subscribe(
      {
        next: (res) => {
          res.forEach(line => {
            this.linkedLines.add(line);
          });
          this.isSavingData = false;
        },
        error: (e) => {
          this.isSavingData = false;
        }
      }
    );
  }

  openAddResponsiblePopup(responsibleId: number | null) {
    if (this.selectedLineId == null) { return; }

    this.hasResponsibleToChange = true;

    this.selectedParentId = responsibleId;
    this.responsibleToModify = null;
  }

  openEditResponsiblePopup(responsibleId:number) {
    const responsible = this.responsiblesList.find(x => x.id === responsibleId);
    if(responsible == null) { return; }

    this.hasResponsibleToChange = true;

    this.responsibleToModify = responsible;
  }
  
  closeResponsiblePopup(responsible: Responsible | null) {
    this.hasResponsibleToChange = false;
    this.responsibleToModify = null;
    this.selectedParentId = null;
    if (responsible != null) {
      const fgIdx = this.responsiblesList.findIndex(x => x.id === responsible.id);
      if (fgIdx !== -1) {
        this.responsiblesList[fgIdx] = responsible;
      } else {
        this.responsiblesList.push(responsible);
      }
      this.responsiblesList = [...this.responsiblesList];
      this.selectResponsible(responsible.id);
    }
  }

}
