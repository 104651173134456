<app-error-page-message *ngIf="isLoadingAuth" text="{{'WAIT MESSAGE' | translate}}"></app-error-page-message>
<app-error-page-message *ngIf="!isLoadingAuth && !isAuth" text="{{'NO AUTHORIZATION MESSAGE' | translate}}"></app-error-page-message>

<div class="line-container" *ngIf="!isLoadingAuth && isAuth">
    <div class="element-container">
        <span class="title">{{ 'ADMIN.FACTORY' | translate }}</span>
        <svg *ngIf="isSuperAdmin" class="add-element" (click)="openCreatePopUp('FACTORY')" width="20" height="20" viewBox="0 0 107 107" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M53.5 26.5239V80.4763" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M26.5239 53.5H80.4763" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/>
        </svg>
        <div class="drag-list">
            <div *ngFor="let factory of factories" class="drag-item drag-item-factory" (click)="selectFactory(factory)" [ngClass]="{'selected' :selectedFactory != null && selectedFactory.id === factory.id}">
                {{factory.name}}
                <div class="action-container">
                    <svg (click)="openModifyPopUp('FACTORY', factory, $event)" xmlns="http://www.w3.org/2000/svg" height="15" viewBox="0 0 24 24" width="15"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/></svg>
                    <svg (click)="openDeletePopUp('FACTORY', factory, $event)" width="14" height="14" class="trash" viewBox="0 0 93 103" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M46.5 30.1055V86.316"/><path d="M62.8945 30.1055V86.316"/><path d="M30.1055 30.1055V86.316"/><path d="M76.993 100.369H16.0983C14.8559 100.369 13.6645 99.875 12.786 98.9965C11.9076 98.1181 11.4141 96.9266 11.4141 95.6843V16.0527H81.6772V95.6843C81.6772 96.9266 81.1837 98.1181 80.3052 98.9965C79.4268 99.875 78.2353 100.369 76.993 100.369Z"/><path d="M2 16.0527H91"/><path d="M67.5788 16.0526H25.4209L30.1051 2H62.8946L67.5788 16.0526Z"/></svg>
                </div>
                <div class="detailed-info" [ngClass]="{'active': selectedFactory != null && selectedFactory.id === factory.id}">
                    <span>{{factory.timeZoneDisplayName}}</span>
                    <span>{{"ADMIN.FACTORY ID" | translate}} {{factory.factoryId}}</span>
                    <span>{{"ADMIN.BRAND FACTORY ID" | translate}} {{factory.brandFactoryId}}</span>
                </div>
            </div>
        </div>
    </div>

    <app-direction-arrow *ngIf="hasSelectedFactory"></app-direction-arrow>

    <div class="element-container" *ngIf="hasSelectedFactory">
        <span class="title">{{ 'ADMIN.PRIMARY LINE' | translate }}</span>
        <svg class="add-element" (click)="openCreatePopUp('PRIMARY LINE')" width="20" height="20" viewBox="0 0 107 107" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M53.5 26.5239V80.4763" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M26.5239 53.5H80.4763" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/>
        </svg>
        <div class="drag-list">
            <div *ngFor="let line of getPrimaryLines()" class="drag-item drag-item-line" (click)="selectProductionLine(line)" [ngClass]="{'selected' :selectedProductionLine != null && selectedProductionLine.id === line.id}">
                <span>{{line.name}}<span *ngIf="line.nextWorkcells.length > 0"> | </span></span> <div><span class="after-zone" *ngFor="let workcell of line.nextWorkcells">{{workcell.name}}</span></div>
                <div class="action-container">
                    <svg (click)="openModifyPopUp('PRIMARY LINE', line, $event)" xmlns="http://www.w3.org/2000/svg" height="15" viewBox="0 0 24 24" width="15"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/></svg>
                    <svg (click)="openDeletePopUp('PRIMARY LINE', line, $event)" width="14" height="14" class="trash" viewBox="0 0 93 103" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M46.5 30.1055V86.316"/><path d="M62.8945 30.1055V86.316"/><path d="M30.1055 30.1055V86.316"/><path d="M76.993 100.369H16.0983C14.8559 100.369 13.6645 99.875 12.786 98.9965C11.9076 98.1181 11.4141 96.9266 11.4141 95.6843V16.0527H81.6772V95.6843C81.6772 96.9266 81.1837 98.1181 80.3052 98.9965C79.4268 99.875 78.2353 100.369 76.993 100.369Z"/><path d="M2 16.0527H91"/><path d="M67.5788 16.0526H25.4209L30.1051 2H62.8946L67.5788 16.0526Z"/></svg>
                </div>
                <mat-icon *ngIf="line.isDefaultLine" class="mat-icon-rtl-mirror isDefaultLine small">{{'beenhere'}}</mat-icon>
                <div class="detailed-info" [ngClass]="{'active': selectedProductionLine != null && selectedProductionLine.id === line.id}">
                    <span>{{line.code}}</span>
                </div>
            </div>
        </div>
    </div>

    <app-direction-arrow *ngIf="hasSelectedProductionLine"></app-direction-arrow>

    <div class="element-container" *ngIf="hasSelectedProductionLine">
        <span class="title">{{ 'ADMIN.ZONE' | translate }}</span>
        <svg class="add-element" (click)="openCreatePopUp('ZONE')" width="20" height="20" viewBox="0 0 107 107" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M53.5 26.5239V80.4763" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M26.5239 53.5H80.4763" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/>
        </svg>
        <div cdkDropList (cdkDropListDropped)="onDropZone($event)" [cdkDropListData]="selectedProductionLine.productionZones" class="drag-list">
            <div cdkDrag *ngFor="let zone of selectedProductionLine.productionZones" class="drag-item" (click)="selectProductionZone(zone)" [ngClass]="{'selected' :selectedProductionZone != null && selectedProductionZone.id === zone.id}">
                {{zone.name}}<div class="after-zone"></div>
                <div class="action-container">
                    <svg (click)="openModifyPopUp('ZONE', zone, $event)" xmlns="http://www.w3.org/2000/svg" height="15" viewBox="0 0 24 24" width="15"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/></svg>
                    <svg (click)="openDeletePopUp('ZONE', zone, $event)" width="14" height="14" class="trash" viewBox="0 0 93 103" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M46.5 30.1055V86.316"/><path d="M62.8945 30.1055V86.316"/><path d="M30.1055 30.1055V86.316"/><path d="M76.993 100.369H16.0983C14.8559 100.369 13.6645 99.875 12.786 98.9965C11.9076 98.1181 11.4141 96.9266 11.4141 95.6843V16.0527H81.6772V95.6843C81.6772 96.9266 81.1837 98.1181 80.3052 98.9965C79.4268 99.875 78.2353 100.369 76.993 100.369Z"/><path d="M2 16.0527H91"/><path d="M67.5788 16.0526H25.4209L30.1051 2H62.8946L67.5788 16.0526Z"/></svg>
                </div>
            </div>
        </div>
    </div>

    <app-direction-arrow *ngIf="hasSelectedProductionZone"></app-direction-arrow>

    <div class="element-container" *ngIf="hasSelectedProductionZone">
        <span class="title">{{ 'ADMIN.WORKCELLS & CHECKPOINTS' | translate }}</span>
        <svg class="add-element" (click)="openCreatePopUp('WORKCELL')" width="20" height="20" viewBox="0 0 107 107" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M53.5 26.5239V80.4763" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M26.5239 53.5H80.4763" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/>
        </svg>
        <div cdkDropList (cdkDropListDropped)="onDropWorkcell($event)" [cdkDropListData]="selectedProductionZone.workcells" class="drag-list">
            <div cdkDrag *ngFor="let workcell of selectedProductionZone.workcells" class="drag-item" [ngClass]="[workcell.isCheckpoint ? 'Checkpoint' : 'Workcell', workcell.disabled ? 'disabled' : '']">
                {{workcell.name}}<div class="type"></div>
                <div class="action-container">
                    <svg (click)="openModifyPopUp('WORKCELL', workcell, $event)" xmlns="http://www.w3.org/2000/svg" height="15" viewBox="0 0 24 24" width="15"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/></svg>
                    <svg (click)="openDeletePopUp('WORKCELL', workcell, $event)" *ngIf="!workcell.disabled" width="14" height="14" class="trash" viewBox="0 0 93 103" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M46.5 30.1055V86.316"/><path d="M62.8945 30.1055V86.316"/><path d="M30.1055 30.1055V86.316"/><path d="M76.993 100.369H16.0983C14.8559 100.369 13.6645 99.875 12.786 98.9965C11.9076 98.1181 11.4141 96.9266 11.4141 95.6843V16.0527H81.6772V95.6843C81.6772 96.9266 81.1837 98.1181 80.3052 98.9965C79.4268 99.875 78.2353 100.369 76.993 100.369Z"/><path d="M2 16.0527H91"/><path d="M67.5788 16.0526H25.4209L30.1051 2H62.8946L67.5788 16.0526Z"/></svg>
                    <mat-icon (click)="openRestorePopUp('WORKCELL', workcell, $event)" *ngIf="workcell.disabled" class="mat-icon-rtl-mirror on-hover">{{'undo'}}</mat-icon>
                </div>
            </div>
        </div>
    </div>

    <!--SECONDARY-->
    <app-direction-arrow *ngIf="hasSelectedFactory" style="grid-column: 2; grid-row: 2;"></app-direction-arrow>

    <div class="element-container" *ngIf="hasSelectedFactory" style="grid-column: 3; grid-row: 2;">
        <span class="title">{{ 'ADMIN.SECONDARY LINE' | translate }}</span>
        <svg class="add-element" (click)="openCreatePopUp('SECONDARY LINE')" width="20" height="20" viewBox="0 0 107 107" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M53.5 26.5239V80.4763" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M26.5239 53.5H80.4763" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/>
        </svg>
        <div class="drag-list">
            <div *ngFor="let line of getSecondaryLines()" class="drag-item drag-item-line" (click)="selectSecondaryLine(line)" [ngClass]="{'selected' :selectedSecondaryLine != null && selectedSecondaryLine.id === line.id}">
                {{line.name}} <span class="after-zone" *ngFor="let workcell of line.nextWorkcells">{{workcell.name}}</span>
                <div class="action-container">
                    <svg (click)="openModifyPopUp('SECONDARY LINE', line, $event)" xmlns="http://www.w3.org/2000/svg" height="15" viewBox="0 0 24 24" width="15"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/></svg>
                    <svg (click)="openDeletePopUp('SECONDARY LINE', line, $event)" width="14" height="14" class="trash" viewBox="0 0 93 103" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M46.5 30.1055V86.316"/><path d="M62.8945 30.1055V86.316"/><path d="M30.1055 30.1055V86.316"/><path d="M76.993 100.369H16.0983C14.8559 100.369 13.6645 99.875 12.786 98.9965C11.9076 98.1181 11.4141 96.9266 11.4141 95.6843V16.0527H81.6772V95.6843C81.6772 96.9266 81.1837 98.1181 80.3052 98.9965C79.4268 99.875 78.2353 100.369 76.993 100.369Z"/><path d="M2 16.0527H91"/><path d="M67.5788 16.0526H25.4209L30.1051 2H62.8946L67.5788 16.0526Z"/></svg>
                </div>
                <mat-icon *ngIf="line.isDefaultLine" class="mat-icon-rtl-mirror isDefaultLine small">{{'beenhere'}}</mat-icon>
                <div class="detailed-info" [ngClass]="{'active': selectedSecondaryLine != null && selectedSecondaryLine.id === line.id}">
                    <span>{{line.code}}</span>
                </div>
            </div>
        </div>
    </div>

    
    <app-direction-arrow *ngIf="hasSelectedSecondaryLine" style="grid-column: 4; grid-row: 2;"></app-direction-arrow>

    <div class="element-container" *ngIf="hasSelectedSecondaryLine" style="grid-column: 5; grid-row: 2;">
        <span class="title">{{ 'ADMIN.ZONE' | translate }}</span>
        <svg class="add-element" (click)="openCreatePopUp('SECONDARYZONE')" width="20" height="20" viewBox="0 0 107 107" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M53.5 26.5239V80.4763" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M26.5239 53.5H80.4763" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/>
        </svg>
        <div cdkDropList (cdkDropListDropped)="onDropSecondaryZone($event)" [cdkDropListData]="selectedSecondaryLine.productionZones" class="drag-list">
            <div cdkDrag *ngFor="let zone of selectedSecondaryLine.productionZones" class="drag-item" (click)="selectSecondaryZone(zone)" [ngClass]="{'selected' :selectedSecondaryZone.id === zone.id}">
                {{zone.name}}
                <div class="action-container">
                    <svg (click)="openModifyPopUp('SECONDARYZONE', zone, $event)" xmlns="http://www.w3.org/2000/svg" height="15" viewBox="0 0 24 24" width="15"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/></svg>
                    <svg (click)="openDeletePopUp('SECONDARYZONE', zone, $event)" width="14" height="14" class="trash" viewBox="0 0 93 103" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M46.5 30.1055V86.316"/><path d="M62.8945 30.1055V86.316"/><path d="M30.1055 30.1055V86.316"/><path d="M76.993 100.369H16.0983C14.8559 100.369 13.6645 99.875 12.786 98.9965C11.9076 98.1181 11.4141 96.9266 11.4141 95.6843V16.0527H81.6772V95.6843C81.6772 96.9266 81.1837 98.1181 80.3052 98.9965C79.4268 99.875 78.2353 100.369 76.993 100.369Z"/><path d="M2 16.0527H91"/><path d="M67.5788 16.0526H25.4209L30.1051 2H62.8946L67.5788 16.0526Z"/></svg>
                </div>
            </div>
        </div>
    </div>

    <app-direction-arrow *ngIf="hasSelectedSecondaryZone" style="grid-column: 6; grid-row: 2;"></app-direction-arrow>

    <div class="element-container" *ngIf="hasSelectedSecondaryZone" style="grid-column: 7; grid-row: 2;">
        <span class="title">{{ 'ADMIN.WORKCELLS & CHECKPOINTS' | translate }}</span>
        <svg class="add-element" (click)="openCreatePopUp('SECONDARYWORKCELL')" width="20" height="20" viewBox="0 0 107 107" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M53.5 26.5239V80.4763" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M26.5239 53.5H80.4763" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round"/>
        </svg>
        <div cdkDropList (cdkDropListDropped)="onDropSecondaryWorkcell($event)" [cdkDropListData]="selectedSecondaryZone.workcells" class="drag-list">
            <div cdkDrag *ngFor="let workcell of selectedSecondaryZone.workcells" class="drag-item" [ngClass]="[workcell.isCheckpoint ? 'Checkpoint' : 'Workcell']">
                {{workcell.name}}<div class="type"></div>
                <div class="action-container">
                    <svg (click)="openModifyPopUp('SECONDARYWORKCELL', workcell, $event)" xmlns="http://www.w3.org/2000/svg" height="15" viewBox="0 0 24 24" width="15"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/></svg>
                    <svg (click)="openDeletePopUp('SECONDARYWORKCELL', workcell, $event)" width="14" height="14" class="trash" viewBox="0 0 93 103" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M46.5 30.1055V86.316"/><path d="M62.8945 30.1055V86.316"/><path d="M30.1055 30.1055V86.316"/><path d="M76.993 100.369H16.0983C14.8559 100.369 13.6645 99.875 12.786 98.9965C11.9076 98.1181 11.4141 96.9266 11.4141 95.6843V16.0527H81.6772V95.6843C81.6772 96.9266 81.1837 98.1181 80.3052 98.9965C79.4268 99.875 78.2353 100.369 76.993 100.369Z"/><path d="M2 16.0527H91"/><path d="M67.5788 16.0526H25.4209L30.1051 2H62.8946L67.5788 16.0526Z"/></svg>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="popup-container" [ngClass]="{'active' :popUpType != ''}">
    <div class="create-popup" [ngClass]="{'production-line' :popUpType == 'PRIMARY LINE' || popUpType == 'SECONDARY LINE'}">
        <svg (click)="closeCreatePopUp()" class="close-icon" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 28.062 28.062">
            <path class="cls-1" d="M5.469,30.371l24.4-24.4,3.66,3.66-24.4,24.4Z" transform="translate(-5.469 -5.969)"/>
            <path class="cls-1" d="M9.127,5.97l24.407,24.4-3.66,3.661L5.467,9.631Z" transform="translate(-5.469 -5.969)"/>
        </svg>

        <span class="title">{{getPopupTitle()}}</span>
        
        <span class="text">{{getPopupText()}}<br>{{ 'ADMIN.CONFIRM' | translate }}</span>

        <form [formGroup]="createForm" *ngIf="popUpFunction !== 'DELETE' && popUpFunction !== 'RESTORE'">
            <!--All Name-->
            <input  #nameInput type="text" id="nameInput" class="" formControlName="nameInput" placeholder="{{ 'ADMIN.NAME' | translate }}">

            <!--Factory Timezone-->
            <mat-select id="timezone" formControlName="timezone" required *ngIf="popUpType === 'FACTORY'" placeholder="{{ 'ADMIN.TIMEZONE' | translate }}">
                <mat-option *ngFor="let timezone of timezones" value={{timezone.displayName}}>{{timezone.displayName}}</mat-option>
            </mat-select>

            <!--Factory Id-->
            <input type="text" id="factoryId" class="" formControlName="factoryId" *ngIf="popUpType === 'FACTORY'" placeholder="{{ 'ADMIN.FACTORY ID' | translate }}">

            <!--Factory Brand Id-->
            <input type="text" id="brandFactoryId" class="" formControlName="brandFactoryId" *ngIf="popUpType === 'FACTORY'" placeholder="{{ 'ADMIN.BRAND FACTORY ID' | translate }}">

            <!--Workcell type-->
            <div *ngIf="popUpType === 'WORKCELL' || popUpType === 'SECONDARYWORKCELL'" class="checkbox-container" required>
                <input type="checkbox" id="typeWorkcell" formControlName="typeWorkcell">
                <label for="typeWorkcell">Checkpoint</label>
            </div>

            <!--Line Code-->
            <input *ngIf="popUpType === 'PRIMARY LINE' || popUpType === 'SECONDARY LINE'" type="text" id="lineCode" class="" formControlName="lineCode" placeholder="{{ 'ADMIN.CODE' | translate }}">
            
            <!--Is Default Line -->
            <div *ngIf="popUpType === 'PRIMARY LINE' || popUpType === 'SECONDARY LINE'" class="checkbox-container">
                <input type="checkbox" id="isDefaultLine" formControlName="isDefaultLine">
                <label for="isDefaultLine">{{'ADMIN.ISDEFAULTLINE' | translate}}</label>
            </div>

            <!--Secondary Line After Workcell-->
            <div class="next-workcells" *ngIf="popUpType === 'PRIMARY LINE' || popUpType === 'SECONDARY LINE'">
                <label for="next-workcell">{{ 'ADMIN.JOIN OTHER LINES IN' | translate }}:</label>
                <span class="next-workcell" *ngFor="let item of popUpNextWorkcellsList; let index = index;">{{item.factory.name}} - {{item.line.name}} - {{item.zone.name}} - {{item.workcell.name}}
                    <svg (click)="removeNextWorkcell(index)" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 28.062 28.062">
                        <path class="cls-1" d="M5.469,30.371l24.4-24.4,3.66,3.66-24.4,24.4Z" transform="translate(-5.469 -5.969)"/>
                        <path class="cls-1" d="M9.127,5.97l24.407,24.4-3.66,3.661L5.467,9.631Z" transform="translate(-5.469 -5.969)"/>
                    </svg>
                </span>
                <div class="add-next-workcell">
                    <mat-select  value="Not OK" id="deviation-type" formControlName="nextWorkcell" required placeholder="{{ 'ADMIN.END IN WORKCELL' | translate }}:">
                        <mat-option *ngFor="let item of currentFactoryWorkcells" [value]="item.workcell" (click)="addNextWorkcell(item)">{{item.factory.name}} - {{item.line.name}} - {{item.zone.name}} - {{item.workcell.name}}</mat-option>
                    </mat-select>
                </div>
            </div>

             <!--Enable MP Problem Solving-->
             <div *ngIf="popUpType === 'FACTORY'" class="checkbox-container" required>
                <input type="checkbox" id="enableMPProblemSolving" formControlName="enableMPProblemSolving">
                <label for="enableMPProblemSolving">{{'ADMIN.ENABLE MP PROBLEM SOLVING' | translate}}</label>
            </div>

            <!--Display RTAlias-->
            <div *ngIf="popUpType === 'FACTORY'" class="checkbox-container" required>
                <input type="checkbox" id="displayRTAlias" formControlName="displayRTAlias">
                <label for="displayRTAlias">{{'ADMIN.DISPLAY RTALIAS' | translate}}</label>
            </div>

            <!--Enable auto confirm missing part-->
            <div *ngIf="popUpType === 'FACTORY'" class="checkbox-container" required>
                <input type="checkbox" id="autoConfirmMP" formControlName="autoConfirmDeviations">
                <label for="autoConfirmMP">{{'ADMIN.AUTO CONFIRM MISSING PART' | translate}}</label>
            </div>

            <!--Enable deviation problem detail-->
            <div *ngIf="popUpType === 'FACTORY'" class="checkbox-container" required>
                <input type="checkbox" id="deviationProblemDetailEnabled" formControlName="deviationProblemDetailEnabled">
                <label for="deviationProblemDetailEnabled">{{'ADMIN.ENABLE DEVIATION PROBLEM DETAIL' | translate}}</label>
            </div>

            <!--Enable deviation location-->
            <div *ngIf="popUpType === 'FACTORY'" class="checkbox-container" required>
                <input type="checkbox" id="deviationLocationEnabled" formControlName="deviationLocationEnabled">
                <label for="deviationLocationEnabled">{{'ADMIN.ENABLE DEVIATION LOCATION' | translate}}</label>
            </div>

            <!--Enable operator scanning-->
            <div *ngIf="popUpType === 'FACTORY'" class="checkbox-container" required>
                <input type="checkbox" id="operatorScanningEnabled" formControlName="operatorScanningEnabled">
                <label for="operatorScanningEnabled">{{'ADMIN.ENABLE OPERATOR SCANNING' | translate}}</label>
            </div>

            <!--Allow unknown operator-->
            <div *ngIf="popUpType === 'FACTORY'" class="checkbox-container" required>
                <input type="checkbox" id="allowUnknownOperator" formControlName="allowUnknownOperator">
                <label for="allowUnknownOperator">{{'ADMIN.ALLOW UNKNOWN OPERATOR' | translate}}</label>
            </div>
        </form>
        <app-demo-button *ngIf="popUpFunction === 'ADD'" text="{{ 'ADMIN.ADD' | translate }}" color="tertiary" (clickEvent)="create()"></app-demo-button>
        <app-demo-button *ngIf="popUpFunction === 'MODIFY'" text="{{ 'ADMIN.MODIFY' | translate }}" color="tertiary" (clickEvent)="modify()"></app-demo-button>
        <app-demo-button *ngIf="popUpFunction === 'DELETE'" text="{{ 'ADMIN.DELETE' | translate }}" color="tertiary" (clickEvent)="deleteElement()"></app-demo-button>
        <app-demo-button *ngIf="popUpFunction === 'RESTORE'" text="{{ 'ADMIN.RESTORE' | translate }}" color="tertiary" (clickEvent)="restoreElement()"></app-demo-button>
    </div>    
</div>