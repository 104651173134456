import {FlatTreeControl} from '@angular/cdk/tree';
import {Component, EventEmitter, HostListener, Input, Output, SimpleChanges} from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FunctionGroup } from 'src/app/models/functionGroup.model';
import { MissingCause } from 'src/app/models/missingCause.model';
import * as Diacritics from 'diacritics';
import { Responsible } from 'src/app/models/responsible.model';

//FUNCTION
interface ElementNode {
  functionGroup:FunctionGroup;
  children: ElementNode[];
}

/** Flat node with expandable and level information */
interface ElementFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: 'app-tree-data',
  templateUrl: './tree-data.component.html',
  styleUrls: ['./tree-data.component.less']
})

export class TreeDataComponent {
  @Input() functionsList?: FunctionGroup[];
  @Input() currentSelectedFunction?: FunctionGroup | null;
  @Input() missingCauseList?: MissingCause[];
  @Input() currentSelectedMissingCause?: MissingCause | null;
  @Input() responsiblesList?: Responsible[];
  @Input() currentSelectedResponsible?: Responsible | null;
  @Input() temporaryPlaceholder?: string;
  @Input() treeType?: string;

  @Input() isDisabled?: boolean;
  @Output() selectEvent = new EventEmitter<any>();
  @Output() addEvent = new EventEmitter<any>();
  @Output() editEvent = new EventEmitter<any>();
  @Output() toggleEnabledEvent = new EventEmitter<any>();

  treeList: ElementNode[] = [];
  originalTreeList: ElementNode[] = [];
  selectedFunction?: FunctionGroup | null;
  selectedMissingCause?: MissingCause | null;
  selectedResponsible?: Responsible | null;
  filterInputText = "";
  private filterChangedSubject = new Subject();

  isFilterInputFocus = true;
  isBlurCanceled = false;
  //isBlurDisable:boolean = false;

  isSettingsOpen = false;
  selectedLevelOption = 4;
  showCode = true;

  //warning
  warningFieldNotVoid = false;
  @Output() fieldNotVoidEvent = new EventEmitter<any>();

  private _transformer = (node: ElementNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: `${node.functionGroup.id}/${node.functionGroup.code} ${this.getDisplayName(node.functionGroup)}`,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<ElementFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor( private translate: TranslateService) {
    this.dataSource.data = [];
  }

  ngOnInit() {
    this.selectedLevelOption = Number(localStorage.getItem("chosenFunctionLevel")) || 4;
    this.showCode = localStorage.getItem("showTreeCode") != null ? localStorage.getItem("showTreeCode") === "true": true;
    this.filterChangedSubject.pipe(debounceTime(500)).subscribe((event:any) => {
      this.filter((event.target as HTMLInputElement).value);
    
      if ((event.target as HTMLInputElement).value){
        this.isSettingsOpen = false;
        this.expandTreeWithLevel((event.target as HTMLInputElement).value);
      }else{
        this.treeControl.collapseAll();
      }

      this.warningFieldNotVoid = this.filterInputText !== "";
      this.fieldNotVoidEvent.emit(this.warningFieldNotVoid);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['functionsList'] != null) {
      const expandMap = new Map();
      this.treeControl.dataNodes.forEach(node => {
        expandMap.set(Number(node.name.split("/", 1)[0]), this.treeControl.isExpanded(node));
      });
      
      this.functionsList = changes['functionsList'].currentValue;
      this.setTreeList(this.functionsList);
      this.originalTreeList = this.treeList;

      this.treeControl.dataNodes.forEach(node => {
        const id = Number(node.name.split("/", 1)[0]);
        if (expandMap.has(id) && expandMap.get(id)) {
          this.treeControl.expand(node);
        }
      });
    }

    if (changes['missingCauseList'] != null) {
      this.missingCauseList = changes['missingCauseList'].currentValue;
      this.setTreeList(this.missingCauseList);
      this.originalTreeList = this.treeList;
    }

    if (changes['responsiblesList'] != null) {
      const expandMap = new Map();
      this.treeControl.dataNodes.forEach(node => {
        expandMap.set(Number(node.name.split("/", 1)[0]), this.treeControl.isExpanded(node));
      });

      this.responsiblesList = changes['responsiblesList'].currentValue;
      this.setTreeList(this.responsiblesList);
      this.originalTreeList = this.treeList;

      this.treeControl.dataNodes.forEach(node => {
        const id = Number(node.name.split("/", 1)[0]);
        if (expandMap.has(id) && expandMap.get(id)) {
          this.treeControl.expand(node);
        }
      });
    }

    if (changes['currentSelectedFunction'] != null) {
      this.selectedFunction = changes['currentSelectedFunction'].currentValue;
    }
    if (changes['currentSelectedMissingCause'] != null) {
      this.selectedMissingCause = changes['currentSelectedMissingCause'].currentValue;
    }
    if (changes['currentSelectedResponsible'] != null) {
      this.selectedResponsible = changes['currentSelectedResponsible'].currentValue;
    }
  }

  hasChild = (_: number, node: ElementFlatNode) => node.expandable;

  //Start setTreeList decomposition
  setTreeList(elementList?: any[]) {
    if (!elementList) { return; }
  
    // Sort and initialize
    elementList = this.sortElementList(elementList);
    this.treeList = [];
  
    // Determine the type of processing
    if (this.functionsList || this.responsiblesList) {
      this.buildFunctionOrResponsibleTree(elementList);
    } else if (this.missingCauseList) {
      this.buildCauseTree(elementList);
    }
  
    this.dataSource.data = this.treeList;
  }
  
  private buildFunctionOrResponsibleTree(elementList: any[]) {
    elementList.forEach(f => {
      let list = this.treeList;
      while (f.parentId && list.length > 0) {
        const parent = list[list.length - 1];
        list = parent.children;
        if (parent.functionGroup.id === f.parentId) {
          list.push({ functionGroup: f, children: []});
          return;
        }
      }
      this.treeList.push({ functionGroup: f, children: []});
    });
  }
  
  private buildCauseTree(elementList: any[]) {
    elementList.forEach(f => {
      if((f.level3 === "" || f.level3 == null) && (f.level2 !== "" && f.level2 != null)){
          this.treeList.push({ functionGroup: f, children: []});
      }
      else if(f.level3 !== "" && f.level3 != null){
        const found:ElementNode = this.treeList.find(x=>x.functionGroup.level2 === f.level2)!;
        this.addToTreeList(found, f);
      } 
    });
  }

  private addToTreeList(elementParent:ElementNode | null, element:any){
    if(elementParent != null){
      elementParent.children?.push({ functionGroup: element, children: []});
    }
    else{
      this.treeList.push({ functionGroup: element, children: []});
    } 
  }
  //End setTreeList decomposition  

  sortElementList(items:any[]): any[] {
    // Create a map where keys are the parentId and values are arrays of items
    const map = new Map();
    const allIds = new Set(items.map(item => item.id));
  
    items.forEach(item => {
      const parentId = item.parentId || null;
      if (!map.has(parentId)) {
        map.set(parentId, []);
      }
      map.get(parentId).push(item);
    });
  
    // Find root elements (those that have a parentId but whose parent doesn't exist)
    const roots = items.filter(item => !allIds.has(item.parentId));

    // Recursive function to sort and flatten the hierarchy
    function addChildren(parentId:any) {
      const children = map.get(parentId) || [];
      children.sort((a:any, b:any) => {
        if (a.code === b.code) {
          return a.id - b.id;  // Sort by id if codes are equal
        }
        return a.code - b.code;  // Sort by code
      });
      return children.reduce((acc:any, child:any) => {
        return acc.concat(child, addChildren(child.id));
      }, []);
    }
  
    // Start with root elements
    return roots.reduce((acc, root) => acc.concat(root, addChildren(root.id)), []);
  }

  //LANGUAGE SETTING
  getDisplayName(f: any): string {
    if (f == null) {
      return "";
    }

    const languageMapping: { [key: string]: string | undefined } = {
        "fr": f.nameFR,
        "nl": f.nameNL,
        "en": f.nameEN
    };

    return languageMapping[this.translate.currentLang] || "";
  }

  getDisplayNameWithCode(f:any):string{
    if(f == null){ return "";}

    if(f.code != null){
      return `${f.code} ${this.getDisplayName(f)}`;
    }
    else{
      return this.getDisplayName(f);
    }
  }

  selectNode(node:ElementFlatNode){
    if(node == null){ return;}

    const id = Number(node.name.split("/", 1)[0]);

    if(this.functionsList != null){
      this.selectedFunction = this.functionsList?.find(x=>x.id === id);
      this.selectEvent.emit(id);
    } 
    else if (this.missingCauseList != null){
      this.selectedMissingCause = this.missingCauseList?.find(x=>x.id === id);
      this.selectEvent.emit(id);
    }
    else if (this.responsiblesList != null){
      this.selectedResponsible = this.responsiblesList?.find(x=>x.id === id);
      this.selectEvent.emit(id);
    }

    this.warningFieldNotVoid = false;
    this.fieldNotVoidEvent.emit(this.warningFieldNotVoid);
    
  }

  addNode(event: Event, node?: ElementFlatNode){
    event.stopPropagation();
    this.addEvent.emit(node ? Number(node.name.split("/", 1)[0]) : null);
  }

  editNode(event: Event, node: ElementFlatNode){
    event.stopPropagation();
    this.editEvent.emit(Number(node.name.split("/", 1)[0]));
  }

  filterChanged(event:any) {
    if(this.filterInputText.length !== 1){
      this.filterChangedSubject.next(event);
    }
  }

  //Start filter tree list function
  filter(filterText:string){
    this.reinitializeTreeList();

    if(!filterText){
      return;
    }

    const filteredFunctionList = this.getFilteredFunctionList(filterText);

    this.setTreeList(filteredFunctionList);
  }

  private reinitializeTreeList() {
    if (this.functionsList) {
      this.setTreeList(this.functionsList);
    } else if (this.missingCauseList) {
      this.setTreeList(this.missingCauseList);
    } else if (this.responsiblesList) {
      this.setTreeList(this.responsiblesList);
    }
  }

  private getFilteredFunctionList(filterText: string): FunctionGroup[] {
    const filteredFunctionList: FunctionGroup[] = [];
  
    this.treeList.forEach(node => {
      this.collectFilteredNodes(node, filterText, filteredFunctionList);
    });
  
    return filteredFunctionList;
  }

  private collectFilteredNodes(node: any, filterText: string, filteredFunctionList: FunctionGroup[]) {
    if (this.isNodeNameInFilter(node, filterText)) {
      filteredFunctionList.push(...this.getAllChildren(node, true));
    } else {
      if (this.isInFilter(node, filterText)) {
        filteredFunctionList.push(node.functionGroup);
      }
  
      node.children?.forEach((child:any) => {
        this.collectFilteredNodes(child, filterText, filteredFunctionList);
      });
    }
  }
  //End filter tree list function

  getAllChildren(node:ElementNode, addNode = false):FunctionGroup[]{
    const allChildrenList: FunctionGroup[] = [];

    if(addNode){
      allChildrenList.push(node.functionGroup);
    }
    
    node.children?.forEach(node2 => {
      allChildrenList.push(node2.functionGroup);
      node2.children?.forEach(node3 => {
        allChildrenList.push(node3.functionGroup);
        node3.children?.forEach(node4 => {
          allChildrenList.push(node4.functionGroup);
        });
      });
    });

    return allChildrenList;
  }

  isNodeNameInFilter(node:ElementNode, filterText:string){
    const nodeName = `${node.functionGroup.code} ${this.getDisplayName(node.functionGroup)}`;

    return this.notSensitive(nodeName).includes(this.notSensitive(filterText));
  }

  isInFilter(node:ElementNode, filterText:string){
    const nodeName = `${node.functionGroup.code} ${this.getDisplayName(node.functionGroup)}`;

    if(this.notSensitive(nodeName).includes(this.notSensitive(filterText))){
      return true;
    }
    else{
      if(node.children?.length === 0){
        return false;
      }
      else{
        let isChildrenDisplayed = false;
        node.children?.forEach(child => {
          if(this.isInFilter(child, filterText)){
            isChildrenDisplayed = true;
          }
        });
        return isChildrenDisplayed;
      }
    }
  }

  notSensitive(s:string | null | undefined){
    if(s==null){ return "";}
    return Diacritics.remove(s).toLowerCase();
  }

  onFocus(){
    this.isFilterInputFocus = true;
  }

  stopFocus(event:FocusEvent){
    if(this.isBlurCanceled){
      this.isFilterInputFocus = true;
      this.isBlurCanceled = false;
      //add focus again to input search
    }
  }

  //close tree when click outside component
  inside = false;
  @HostListener("click")
  clicked() {
    this.inside = true;
  }
  @HostListener("document:click") clickedOut() {
    this.inside = false;
  }

  cancelBlur(){
    this.isBlurCanceled = true;
  }

  clear(){
    if(this.filterInputText !== ""){
      this.resetFilter();
      this.warningFieldNotVoid = false;
      this.fieldNotVoidEvent.emit(this.warningFieldNotVoid);
    } else{
      this.clearSelection();
    }
  }

  resetFilter(){
    this.filterInputText = "";
    this.dataSource.data = this.originalTreeList;
  }

  clearSelection(){
    this.selectedFunction = null;
    this.selectedMissingCause = null;
    this.selectedResponsible = null;
    this.temporaryPlaceholder = "";
    this.selectEvent.emit(null);
  }

  //Start isSelectedPath function
  isSelectedPath(node:any):boolean{
    if(!this.isSelectionValid()){ return false;}

    let isSelectedPath = false;
    const nodeId = Number(node.name.split('/', 2)[0]);

    if(this.functionsList != null){
      isSelectedPath = this.isSelectedPathForFunction(nodeId);
    } else if(this.missingCauseList != null){
      isSelectedPath = this.isSelectedPathForMissingCause(node, nodeId);
    } else if(this.responsiblesList != null){
      isSelectedPath = this.isSelectedPathForResponsible(nodeId);
    }

    this.expandNodeIfSelected(node, nodeId, isSelectedPath);
    
    return isSelectedPath;
  }

  private isSelectionValid(): boolean {
    return !(
      (this.selectedFunction == null && this.selectedMissingCause == null && this.selectedResponsible == null) || 
      (this.functionsList == null && this.missingCauseList == null && this.responsiblesList == null)
    );
  }

  private isSelectedPathForFunction(nodeId: number): boolean {
    if (nodeId === this.selectedFunction?.id) { return true; }
    let parentId = this.selectedFunction?.parentId;
    while (parentId) {
      if (nodeId === parentId) { return true; }
      parentId = this.functionsList?.find(x => x.id === parentId)?.parentId;
    }
    return false;
  }

  private isSelectedPathForMissingCause(node: any, nodeId: number): boolean {
    if(node.level === 0){
      return this.missingCauseList?.find(x=> x.level2 === this.selectedMissingCause?.level2)?.id === nodeId; 
    }else if(node.level === 1){
      return this.missingCauseList?.find(x=> x.level3 === this.selectedMissingCause?.level3)?.id === nodeId;
    }
    return false;
  }
  
  private isSelectedPathForResponsible(nodeId: number): boolean {
    if (nodeId === this.selectedResponsible?.id) { return true; }
    let parentId = this.selectedResponsible?.parentId;
    while (parentId) {
      if (nodeId === parentId) { return true; }
      parentId = this.responsiblesList?.find(x => x.id === parentId)?.parentId;
    }
    return false;
  }

  private expandNodeIfSelected(node: any, nodeId: number, isSelectedPath: boolean) {
    if (
      nodeId === this.selectedFunction?.id ||
      nodeId === this.selectedMissingCause?.id ||
      nodeId === this.selectedResponsible?.id
    ) {
      this.treeControl.expandDescendants(node);
    } else if (isSelectedPath) {
      this.treeControl.expand(node);
    }
  }
  //Start isSelectedPath function


  //SETTINGS
  changeOptionLevel(event:any){
    this.selectedLevelOption = event.value;
    localStorage.setItem("chosenFunctionLevel", event.value);
  }

  toggleShowCode(event:any){
    this.showCode = event.checked;
    localStorage.setItem("showTreeCode", event.checked);
  }

  getParentNode(node: ElementFlatNode): ElementFlatNode | null {
    const currentLevel = this.treeControl.getLevel(node);
    if (currentLevel < 1) {
      return null; // No parent for root nodes
    }
  
    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
  
    for (let i = startIndex; i >= 0; i--) {
      const potentialParent = this.treeControl.dataNodes[i];
      if (this.treeControl.getLevel(potentialParent) < currentLevel) {
        return potentialParent;
      }
    }
  
    return null; // Return null if no parent is found
  }
  
  expandTreeWithLevel(filterText:string){
    const nodes:ElementFlatNode[] = this.treeControl.dataNodes;
    this.treeControl.collapseAll();
    nodes.forEach(node => {
      //if the node is in the filter
      if(this.notSensitive(node.name).includes(this.notSensitive(filterText))){
        // Expand parent until root
        let parentNode:ElementFlatNode | null = this.getParentNode(node);
        while (parentNode) {
          this.treeControl.expand(parentNode);
          parentNode = this.getParentNode(parentNode);
        }
        // Expand node and its children if level is lower than selected level
        const nodesToExpand:ElementFlatNode[] = [ node ].concat(this.treeControl.getDescendants(node));
        nodesToExpand.forEach(n => {
          if (this.treeControl.getLevel(n) < this.selectedLevelOption - 1) {
            this.treeControl.expand(n);
          }
        });
      }
    });
  }

  getNodeDisplayName(node: ElementFlatNode) {
    let name = node.name.split('/', 2)[1];
    if (!this.showCode) {
      name = name.replace(/^\S+\s*/, '');
    }
    return name;
  }

  isNodeEnabled(node: ElementFlatNode){
    if (this.functionsList != null) {
      return !this.functionsList.find(x => x.id === Number(node.name.split("/", 1)[0]))?.disabled;
    }
    if (this.responsiblesList != null) {
      return !this.responsiblesList.find(x => x.id === Number(node.name.split("/", 1)[0]))?.disabled;
    }
    return true;
  }

  toggleNodeEnabled(event: Event, node: ElementFlatNode){
    event.stopPropagation();
    this.toggleEnabledEvent.emit(Number(node.name.split("/", 1)[0]));
  }

  isLastLevel(node: ElementFlatNode){
    if (this.functionsList != null) { return node.level === 3; }
    if (this.responsiblesList != null) { return node.level === 2; }
    return false;
  }

  isReadOnly(node: ElementFlatNode){
    if (this.responsiblesList != null) {
      return this.responsiblesList.find(x => x.id === Number(node.name.split("/", 1)[0]))?.parentId === null;
    }
    return false;
  }
}