import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostListener, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { TranslateService } from '@ngx-translate/core';
import { Observer } from 'rxjs';
import { FunctionGroup } from 'src/app/models/functionGroup.model';
import { noWhitespaceValidator } from 'src/app/validators/no-whitespace.validator';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tree-element-popup',
  templateUrl: './tree-element-popup.component.html',
  styleUrl: './tree-element-popup.component.less'
})
export class TreeElementPopupComponent {
  @HostListener('window:keydown.escape') escapeEvent() { this.closePopup(); }

  apiUrl:string =  environment.API_URL;
  
  @Input() selectedFunction: FunctionGroup | null = null;
  @Input() isPopupOpen = false;
  @Input() selectedLineId = -2;
  @Input() parentId: number | null = null;
  
  @Output() closePopupEvent = new EventEmitter<FunctionGroup | null>();
  
  @ViewChild('codeInput') codeInput!: ElementRef;

  errorMessage = "";
  isSavingData = false;
  isFormDisabled = false;
  isFormValid = true;

  functionForm = new FormGroup({
    codeInput: new FormControl('', [Validators.required, noWhitespaceValidator()]),
    nameInput: new FormControl('', [Validators.required, noWhitespaceValidator()]),
    nameENInput: new FormControl('', [Validators.required, noWhitespaceValidator()]),
    nameFRInput: new FormControl('', [Validators.required, noWhitespaceValidator()]),
    nameSVInput: new FormControl('', [Validators.required, noWhitespaceValidator()]),
    nameDEInput: new FormControl('', [Validators.required, noWhitespaceValidator()]),
    nameNLInput: new FormControl('', [Validators.required, noWhitespaceValidator()]),
    namePTInput: new FormControl('', [Validators.required, noWhitespaceValidator()])
  });
  
  popupFunction = "";

  constructor(private httpClient: HttpClient, private translate: TranslateService, private authService: MsalService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isPopupOpen'] && changes['isPopupOpen'].currentValue) {
      this.openPopup(null);
    }
  }

  openPopup(event?:Event | null, disableForm = false){
    event?.stopPropagation();
    this.isFormDisabled = disableForm;

    this.popupFunction = this.selectedFunction === null ? "ADD" : "MODIFY";

    this.setFormValues();

    this.codeInput.nativeElement.focus();

    this.isSavingData = false;
  }

  closePopup(functionGroup?:FunctionGroup | null, emitEvent = true){
    this.selectedFunction = null;
    this.functionForm.reset();
    if(emitEvent){
      this.closePopupEvent.emit(functionGroup);
    }
  }

  setFormValues(){
    this.functionForm.reset();
    this.functionForm.enable();
    
    this.functionForm.setValue({
      codeInput: this.selectedFunction?.code ?? "",
      nameInput: this.getDisplayName(this.selectedFunction),
      nameENInput: this.selectedFunction?.nameEN ?? "",
      nameFRInput: this.selectedFunction?.nameFR ?? "",
      nameSVInput: this.selectedFunction?.nameSV ?? "",
      nameDEInput: this.selectedFunction?.nameDE ?? "",
      nameNLInput: this.selectedFunction?.nameNL ?? "",
      namePTInput: this.selectedFunction?.namePT ?? ""
    });

    if (this.isFormDisabled){
      this.functionForm.disable();
    }
  }

  onFunctionSubmit() {
    this.isSavingData = true;

    const callback: Partial<Observer<FunctionGroup>> = {
      next: (res) => {
        this.closePopup(res);
        this.isSavingData = false;
      },
      error: (e) => {
        console.error(e);
        this.isSavingData = false;
      }
    };

    if (this.selectedFunction == null) {
      this.createFunction(callback);
    } else {
      this.modifyFunction(callback);
    }
  }

  createFunction(callback: Partial<Observer<FunctionGroup>>){
    const newFunction = new FunctionGroup();
    newFunction.parentId = this.parentId;

    this.applyForm(newFunction);

    this.httpClient.post<FunctionGroup>(`${this.apiUrl}FunctionGroup/${this.selectedLineId}`, newFunction).subscribe(callback);
  }

  modifyFunction(callback: Partial<Observer<FunctionGroup>>){
    this.applyForm(this.selectedFunction!);

    this.httpClient.put<FunctionGroup>(`${this.apiUrl}FunctionGroup/${this.selectedLineId}`, this.selectedFunction).subscribe(callback);
  }

  applyForm(functionGroup: FunctionGroup) {
    functionGroup.code = this.functionForm.value.codeInput ?? "";
    functionGroup.nameEN = this.functionForm.value.nameENInput ?? "";
    functionGroup.nameFR = this.functionForm.value.nameFRInput ?? "";
    functionGroup.nameSV = this.functionForm.value.nameSVInput ?? "";
    functionGroup.nameDE = this.functionForm.value.nameDEInput ?? "";
    functionGroup.nameNL = this.functionForm.value.nameNLInput ?? "";
    functionGroup.namePT = this.functionForm.value.namePTInput ?? "";
  }

  //LANGUAGE SETTING
  getDisplayName(f: FunctionGroup | null): string {
    if (f == null) { return ""; }

    const languageMapping: { [key: string]: string } = {
      "fr": f.nameFR || "",
      "nl": f.nameNL || "",
      "en": f.nameEN || ""
    };

    return languageMapping[this.translate.currentLang] || "";
  }

  setFormName() {
    const formLanguageMapping: { [key: string]: FormControl } = {
      "en": this.functionForm.controls[`nameENInput`],
      "fr": this.functionForm.controls[`nameFRInput`],
      "sv": this.functionForm.controls[`nameSVInput`],
      "de": this.functionForm.controls[`nameDEInput`],
      "nl": this.functionForm.controls[`nameNLInput`],
      "pt": this.functionForm.controls[`namePTInput`]
    };

    const currentLangInput = formLanguageMapping[this.translate.currentLang];
    if (currentLangInput == null) { return; }
    currentLangInput.setValue(this.functionForm.value.nameInput);
    currentLangInput.updateValueAndValidity();

    ['en', 'fr', 'sv', 'de', 'nl', 'pt'].forEach(lang => {
      const langInput = formLanguageMapping[lang];
      if (langInput == null || langInput.value !== "") { return; }
      langInput.setValue(this.functionForm.value.nameInput);
      langInput.updateValueAndValidity();
    });
  }
  
  getPopupTitle(): any{
    return this.translate.instant('ADMIN.' + this.popupFunction + ' FUNCTION') + ' ' + (this.selectedFunction?.id ?? '');
  }

  getPopupText(): any{
    return this.translate.instant('ADMIN.' + this.popupFunction + ' TEXT');
  }

  getLanguage(): string {
    return this.translate.currentLang.toLocaleUpperCase();
  }
}
