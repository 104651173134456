import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostListener, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { TranslateService } from '@ngx-translate/core';
import { Observer } from 'rxjs';
import { FunctionGroup } from 'src/app/models/functionGroup.model';
import { Responsible } from 'src/app/models/responsible.model';
import { noWhitespaceValidator } from 'src/app/validators/no-whitespace.validator';
import { environment } from 'src/environments/environment';

type ListType = FunctionGroup | Responsible;

@Component({
  selector: 'app-tree-element-popup',
  templateUrl: './tree-element-popup.component.html',
  styleUrl: './tree-element-popup.component.less'
})
export class TreeElementPopupComponent {
  @HostListener('window:keydown.escape') escapeEvent() { this.closePopup(); }

  apiUrl:string =  environment.API_URL;
  
  @Input() selectedElement: ListType | null = null;
  @Input() isPopupOpen = false;
  @Input() selectedLineId = -2;
  @Input() selectedFaultTypeId = -1;
  @Input() parentId: number | null = null;
  @Input() elementType = "Function";
  
  @Output() closeFunctionPopupEvent = new EventEmitter<FunctionGroup | null>();
  @Output() closeResponsiblePopupEvent = new EventEmitter<Responsible | null>();
  
  @ViewChild('codeInput') codeInput!: ElementRef;

  errorMessage = "";
  isSavingData = false;
  isFormDisabled = false;
  isFormValid = true;

  elementForm!: FormGroup;
  
  popupFunction = "";

  constructor(private httpClient: HttpClient, private translate: TranslateService, private authService: MsalService) { }

  ngOnInit() {
    this.elementForm = new FormGroup({
      codeInput: new FormControl('', [Validators.required, noWhitespaceValidator()]),
      nameInput: new FormControl('', [Validators.required, noWhitespaceValidator()]),
      nameENInput: new FormControl('', [Validators.required, noWhitespaceValidator()]),
      nameFRInput: new FormControl('', [Validators.required, noWhitespaceValidator()]),
      nameNLInput: new FormControl('', [Validators.required, noWhitespaceValidator()])
    });
    if (this.elementType === "Function") {
      this.elementForm.addControl("nameSVInput", new FormControl('', [Validators.required, noWhitespaceValidator()]));
      this.elementForm.addControl("nameDEInput", new FormControl('', [Validators.required, noWhitespaceValidator()]));
      this.elementForm.addControl("namePTInput", new FormControl('', [Validators.required, noWhitespaceValidator()]));
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isPopupOpen'] && changes['isPopupOpen'].currentValue) {
      this.openPopup(null);
    }
  }

  openPopup(event?: Event | null, disableForm = false){
    event?.stopPropagation();
    this.isFormDisabled = disableForm;

    this.popupFunction = this.selectedElement === null ? "ADD" : "MODIFY";

    this.setFormValues();

    this.codeInput.nativeElement.focus();

    this.isSavingData = false;
  }

  closePopup(element?: ListType | null, emitEvent = true){
    this.selectedElement = null;
    this.elementForm.reset();
    if(emitEvent){
      if (this.elementType === "Function") {
        this.closeFunctionPopupEvent.emit(element as FunctionGroup | null | undefined);
      } else {
        this.closeResponsiblePopupEvent.emit(element as Responsible | null | undefined);
      }
    }
  }

  setFormValues(){
    this.elementForm.reset();
    this.elementForm.enable();

    const formValue: any = {
      codeInput: this.selectedElement?.code ?? "",
      nameInput: this.getDisplayName(this.selectedElement),
      nameENInput: this.selectedElement?.nameEN ?? "",
      nameFRInput: this.selectedElement?.nameFR ?? "",
      nameNLInput: this.selectedElement?.nameNL ?? ""
    };
    if (this.elementType === "Function") {
      formValue.nameSVInput = (this.selectedElement as FunctionGroup | null)?.nameSV ?? "";
      formValue.nameDEInput = (this.selectedElement as FunctionGroup | null)?.nameDE ?? "";
      formValue.namePTInput = (this.selectedElement as FunctionGroup | null)?.namePT ?? "";
    }
    
    this.elementForm.setValue(formValue);

    if (this.isFormDisabled){
      this.elementForm.disable();
    }
  }

  onFormSubmit() {
    this.isSavingData = true;

    const callback: Partial<Observer<ListType>> = {
      next: (res) => {
        this.closePopup(res);
        this.isSavingData = false;
      },
      error: (e) => {
        console.error(e);
        this.isSavingData = false;
      }
    };

    if (this.selectedElement == null) {
      this.createElement(callback);
    } else {
      this.modifyElement(callback);
    }
  }

  createElement(callback: Partial<Observer<ListType>>){
    const newElement = this.elementType === "Function" ? new FunctionGroup() : new Responsible();
    newElement.parentId = this.parentId;

    this.applyForm(newElement);

    if (this.elementType === "Function") {
      this.httpClient.post<FunctionGroup>(`${this.apiUrl}FunctionGroup/${this.selectedLineId}`, newElement).subscribe(callback);
    } else {
      this.httpClient.post<Responsible>(`${this.apiUrl}Responsible/${this.selectedLineId}/${this.selectedFaultTypeId}`, newElement).subscribe(callback);
    }
  }

  modifyElement(callback: Partial<Observer<ListType>>){
    if (this.selectedElement === null) { return; }
    this.applyForm(this.selectedElement);

    const method = this.elementType === "Function" ? "FunctionGroup" : "Responsible";

    this.httpClient.put<ListType>(`${this.apiUrl}${method}/${this.selectedLineId}`, this.selectedElement).subscribe(callback);
  }

  applyForm(element: ListType) {
    element.code = this.elementForm.value.codeInput ?? "";
    element.nameEN = this.elementForm.value.nameENInput ?? "";
    element.nameFR = this.elementForm.value.nameFRInput ?? "";
    element.nameNL = this.elementForm.value.nameNLInput ?? "";
    if (this.elementType === "Function") {
      (element as FunctionGroup).nameSV = this.elementForm.value.nameSVInput ?? "";
      (element as FunctionGroup).nameDE = this.elementForm.value.nameDEInput ?? "";
      (element as FunctionGroup).namePT = this.elementForm.value.namePTInput ?? "";
    }
  }

  //LANGUAGE SETTING
  getDisplayName(f: ListType | null): string {
    if (f == null) { return ""; }

    const languageMapping: { [key: string]: string } = {
      "fr": f.nameFR || "",
      "nl": f.nameNL || "",
      "en": f.nameEN || ""
    };

    return languageMapping[this.translate.currentLang] || "";
  }

  setFormName() {
    const formLanguageMapping: { [key: string]: FormControl } = {
      "en": this.elementForm.controls[`nameENInput`] as FormControl,
      "fr": this.elementForm.controls[`nameFRInput`] as FormControl,
      "sv": this.elementForm.controls[`nameSVInput`] as FormControl,
      "de": this.elementForm.controls[`nameDEInput`] as FormControl,
      "nl": this.elementForm.controls[`nameNLInput`] as FormControl,
      "pt": this.elementForm.controls[`namePTInput`] as FormControl
    };

    const currentLangInput = formLanguageMapping[this.translate.currentLang];
    if (currentLangInput == null) { return; }
    currentLangInput.setValue(this.elementForm.value.nameInput);
    currentLangInput.updateValueAndValidity();

    let langList = ['en', 'fr', 'nl'];
    if (this.elementType === "Function") {
      langList = langList.concat(['sv', 'de', 'pt']);
    }

    langList.forEach(lang => {
      const langInput = formLanguageMapping[lang];
      if (langInput == null || langInput.value !== "") { return; }
      langInput.setValue(this.elementForm.value.nameInput);
      langInput.updateValueAndValidity();
    });
  }
  
  getPopupTitle(): any{
    const title = this.translate.instant(`ADMIN.${this.popupFunction} ${this.elementType.toLocaleUpperCase()}`);
    if (this.selectedElement === null) { return title; }
    return  `${title} ${this.selectedElement.id}`;
  }

  getPopupText(): any{
    return this.translate.instant('ADMIN.' + this.popupFunction + ' TEXT');
  }

  getLanguage(): string {
    return this.translate.currentLang.toLocaleUpperCase();
  }
}
