import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HealthCheckComponent } from './components/health-check/health-check.component';
import { AdministrateListFunctionsComponent } from './pages/administrate-lists/administrate-list-functions/administrate-list-functions.component';
import { AdministrateListTagsComponent } from './pages/administrate-lists/administrate-list-tags/administrate-list-tags.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { ProductionLineSettingsPageComponent } from './pages/production-line-settings-page/production-line-settings-page.component';
import { RolesRightsSettingsPageComponent } from './pages/roles-rights-settings-page/roles-rights-settings-page.component';
import { SuperAdminPageComponent } from './pages/super-admin-page/super-admin-page.component';
import { UsersRolesSettingsPageComponent } from './pages/users-roles-settings-page/users-roles-settings-page.component';
import { AdministrateListAgentsComponent } from './pages/administrate-lists/administrate-list-agents/administrate-list-agents.component';

const routes: Routes = [
  {
  path: '',
  component: ProductionLineSettingsPageComponent
  },
  {
    path: 'health',
    component: HealthCheckComponent
  },
  {
    path: 'settings/production-lines',
    component: ProductionLineSettingsPageComponent
  },
  {
    path: 'settings/roles-rights',
    component: RolesRightsSettingsPageComponent
  },
  {
    path: 'settings/users-roles',
    component: UsersRolesSettingsPageComponent
  },
  {
    path: 'settings/super-admin',
    component: SuperAdminPageComponent
  },
  {
    path: 'administrate-lists/agents',
    component: AdministrateListAgentsComponent
  },
  {
    path: 'administrate-lists/functions',
    component: AdministrateListFunctionsComponent
  },
  {
    path: 'administrate-lists/tags',
    component: AdministrateListTagsComponent
  },
  { 
    path: '**', pathMatch: 'full',  
    component: ErrorPageComponent
  }, 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
