import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
  selector: 'app-demo-button',
  templateUrl: './demo-button.component.html',
  styleUrls: ['./demo-button.component.less']
})
export class DemoButtonComponent implements OnInit {

  @Input() text = "No text"; //any string you want
  @Input() type = "btn-big"; //or btn-small, btn-icon
  @Input() color = "tertiary"; //or secondary, grey, reverse-secondary, reverse-secondary-text, reverse-tertiary, reverse-grey
  @Input() svg = ""; //or check, upload, save, owner, reload, mail, arrow-back
  @Input() disable = false;
  @Input() tooltipText = "";
  @Input() tooltipTexts:string[] = [];

  @Input() url:string = "";

  @Output() clickEvent = new EventEmitter<Event>();

  @HostListener('click', ['$event']) onClick(event: Event) { 
    event.stopPropagation();
    this.clicked(event);
  }
  @HostListener('keyup.enter', ['$event']) onEnterKey(event: KeyboardEvent) {
    event.preventDefault();
    this.clicked(event);
  }

  ngOnInit(): void {
  }

  clicked(event: Event){
    if(!this.disable){
      this.clickEvent.emit(event);
    }
  }
}
