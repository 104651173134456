export class Responsible {

    //LANGUAGE SETTING
    id = 0;
    parentId: number | null = null;
    code = "";
    level1 = "";
    level2: string | null = null;
    level3: string | null = null;
    nameEN: string | null = null;
    nameFR: string | null = null;
    nameNL: string | null = null;
    disabled = false;

    constructor() {}
}