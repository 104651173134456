<app-error-page-message *ngIf="isLoadingAuth" text="{{'WAIT MESSAGE' | translate}}"></app-error-page-message>
<app-error-page-message *ngIf="!isLoadingAuth && !isAuth" text="{{'NO AUTHORIZATION MESSAGE' | translate}}"></app-error-page-message>

<div class="array-container" *ngIf="!isLoadingAuth && isAuth">
    <div class="key-container">
        <span>{{ 'ADMIN.ROLENAME' | translate }}</span>
        <span>{{ 'ADMIN.FACTORY' | translate }}</span>
        <span>{{ 'ADMIN.ADMINISTRATEDATA' | translate }}</span>
        <span>{{ 'ADMIN.CREATEDEVIATION' | translate }}</span>

        <span>{{ 'ADMIN.READDEVIATION' | translate }}</span>
        <span>{{ 'ADMIN.MODIFYDEVIATION' | translate }}</span>
        <span>{{ 'ADMIN.MODIFYSOLVEDDEVIATION' | translate }}</span>
        <span>{{ 'ADMIN.SOLVEDEVIATION' | translate }}</span>
        <span>{{ 'ADMIN.MANAGEDEVIATIONTAGS' | translate }}</span>
        <span>{{ 'ADMIN.READPRODUCTQUALITYSTATUS' | translate }}</span>
        <span>{{ 'ADMIN.READMISSINGPART' | translate }}</span>
        <span>{{ 'ADMIN.MISSINGPARTSMANAGEMENT' | translate }}</span>
        <span>{{ 'ADMIN.VALIDATEMISSINGPART' | translate }}</span>
        <span>{{ 'ADMIN.ADDMISSINGPART' | translate }}</span>
        <span>{{ 'ADMIN.CHECKCHECKPOINT' | translate }}</span>

        <span>{{ 'ADMIN.READSUPPLIERQUALITY' | translate }}</span>
        <span>{{ 'ADMIN.CREATESUPPLIERQUALITY' | translate }}</span>
        <span>{{ 'ADMIN.MODIFYSUPPLIERQUALITY' | translate }}</span>
    </div>
    <div class="lines-container" *ngIf="isAuth">
        <div class="line-container" *ngFor="let role of roles">
            <span>{{role.name}}</span>
            <span>{{role.factory?.name}}</span>
            <div class="checkbox-container">
                <input type="checkbox" [checked]="role.administrateData" (change)="onCheckChange(role, 'administrateData', $event)">
            </div>
            <div class="checkbox-container">
                <input type="checkbox" [checked]="role.createDeviation" (change)="onCheckChange(role, 'createDeviation', $event)">
            </div>
            <div class="checkbox-container">
                <input type="checkbox" [checked]="role.readDeviation" (change)="onCheckChange(role, 'readDeviation', $event)">
            </div>
            <div class="checkbox-container">
                <input type="checkbox" [checked]="role.modifyDeviation" (change)="onCheckChange(role, 'modifyDeviation', $event)">
            </div>
            <div class="checkbox-container">
                <input type="checkbox" [checked]="role.modifySolvedDeviation" (change)="onCheckChange(role, 'modifySolvedDeviation', $event)">
            </div>
            <div class="checkbox-container">
                <input type="checkbox" [checked]="role.solveDeviation" (change)="onCheckChange(role, 'solveDeviation', $event)">
            </div>
            <div class="checkbox-container">
                <input type="checkbox" [checked]="role.manageDeviationTags" (change)="onCheckChange(role, 'manageDeviationTags', $event)">
            </div>
            <div class="checkbox-container">
                <input type="checkbox" [checked]="role.readProductQualityStatus" (change)="onCheckChange(role, 'readProductQualityStatus', $event)">
            </div>
            <div class="checkbox-container">
                <input type="checkbox" [checked]="role.readMissingPart" (change)="onCheckChange(role, 'readMissingPart', $event)">
            </div>
            <div class="checkbox-container">
                <input type="checkbox" [checked]="role.missingPartManagement" (change)="onCheckChange(role, 'missingPartManagement', $event)">
            </div>
            <div class="checkbox-container">
                <input type="checkbox" [checked]="role.validateMissingPart" (change)="onCheckChange(role, 'validateMissingPart', $event)">
            </div>
            <div class="checkbox-container">
                <input type="checkbox" [checked]="role.createMissingPart" (change)="onCheckChange(role, 'createMissingPart', $event)">
            </div>
            <div class="checkbox-container">
                <input type="checkbox" [checked]="role.checkCheckpoint" (change)="onCheckChange(role, 'checkCheckpoint', $event)">
            </div>

            <!--SUPPLIERS-->
            <div class="checkbox-container">
                <input type="checkbox" [checked]="role.readSupplierQuality" (change)="onCheckChange(role, 'readSupplierQuality', $event)">
            </div>
            <div class="checkbox-container">
                <input type="checkbox" [checked]="role.createSupplierQuality" (change)="onCheckChange(role, 'createSupplierQuality', $event)">
            </div>
            <div class="checkbox-container">
                <input type="checkbox" [checked]="role.modifySupplierQuality" (change)="onCheckChange(role, 'modifySupplierQuality', $event)">
            </div>

            <div class="action-container">
                <svg  (click)="openPopUp('MODIFY', role)" xmlns="http://www.w3.org/2000/svg" height="15" viewBox="0 0 24 24" width="15"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/></svg>
                <svg  (click)="openPopUp('DELETE', role)" width="14" height="14" class="trash" viewBox="0 0 93 103" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M46.5 30.1055V86.316"/><path d="M62.8945 30.1055V86.316"/><path d="M30.1055 30.1055V86.316"/><path d="M76.993 100.369H16.0983C14.8559 100.369 13.6645 99.875 12.786 98.9965C11.9076 98.1181 11.4141 96.9266 11.4141 95.6843V16.0527H81.6772V95.6843C81.6772 96.9266 81.1837 98.1181 80.3052 98.9965C79.4268 99.875 78.2353 100.369 76.993 100.369Z"/><path d="M2 16.0527H91"/><path d="M67.5788 16.0526H25.4209L30.1051 2H62.8946L67.5788 16.0526Z"/></svg>
            </div>
        </div>
    </div>
    <app-demo-button (clickEvent)="openPopUp('ADD')" class="add-element" text="{{'ADMIN.ADD ROLE' | translate}}" color="reverse-grey" type="btn-small"></app-demo-button>
</div>

<div *ngIf="isAuth" class="popup-container" [ngClass]="{'active' :popUpFunction != ''}">
    <div class="popup">
        <svg (click)="closePopUp()" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 28.062 28.062">
            <path class="cls-1" d="M5.469,30.371l24.4-24.4,3.66,3.66-24.4,24.4Z" transform="translate(-5.469 -5.969)"/>
            <path class="cls-1" d="M9.127,5.97l24.407,24.4-3.66,3.661L5.467,9.631Z" transform="translate(-5.469 -5.969)"/>
        </svg>

        <span class="title">{{getPopupTitle()}}</span>
        
        <span class="text">{{getPopupText()}}</span>

        <form [formGroup]="roleForm">
            <!--Name-->
            <input #nameInput type="text" id="nameInput" class="" formControlName="nameInput" placeholder="{{ 'ADMIN.NAME' | translate }}" *ngIf="popUpFunction != 'DELETE'">

            <!--Factory-->
            <mat-select  value="Not OK" id="deviation-type" formControlName="factoryInput" required placeholder="{{ 'SELECT FACTORY' | translate }}" *ngIf="popUpFunction != 'DELETE'">
                <mat-option *ngFor="let factory of factories" value={{factory.name}}>{{factory.name}}</mat-option>
            </mat-select>            
        </form>
        <app-demo-button *ngIf="popUpFunction === 'ADD'" text="{{ 'ADMIN.ADD' | translate }}" color="tertiary" (clickEvent)="addRole()"></app-demo-button>
        <app-demo-button *ngIf="popUpFunction === 'MODIFY'" text="{{ 'ADMIN.MODIFY' | translate }}" color="tertiary" (clickEvent)="modifyRole()"></app-demo-button>
        <app-demo-button *ngIf="popUpFunction === 'DELETE'" text="{{ 'ADMIN.DELETE' | translate }}" color="tertiary" (clickEvent)="deleteRole()"></app-demo-button>
    </div>    
</div>
