<app-error-page-message *ngIf="isLoadingAuth" text="{{'WAIT MESSAGE' | translate}}"></app-error-page-message>
<app-error-page-message *ngIf="!isLoadingAuth && !isAuth" text="{{'NO AUTHORIZATION MESSAGE' | translate}}"></app-error-page-message>

<div class="line-container" *ngIf="!isLoadingAuth && isAuth">

    <div class="element-container">
        <span class="title">{{ 'ADMIN.ROLE' | translate }}</span>
        <div class="drag-list">
            <span *ngIf="!isAuth">You are not authorized</span>
            <div *ngFor="let role of roles" class="drag-item" (click)="selectRole(role)" [ngClass]="{'selected' :selectedRole != null && selectedRole.id === role.id}">
                {{role.name}}
            </div>
        </div>
    </div>

    <app-direction-arrow *ngIf="selectedRole != null"></app-direction-arrow>

    <div class="element-container" *ngIf="selectedRole != null">
        <span class="title">{{ 'ADMIN.USER' | translate }}</span>
        <div class="drag-list">
            <div *ngFor="let user of selectedRoleUsers" class="drag-item" >
                {{user.userId}}
                <svg (click)="removeUser(user)" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 28.062 28.062">
                    <path class="cls-1" d="M5.469,30.371l24.4-24.4,3.66,3.66-24.4,24.4Z" transform="translate(-5.469 -5.969)"/>
                    <path class="cls-1" d="M9.127,5.97l24.407,24.4-3.66,3.661L5.467,9.631Z" transform="translate(-5.469 -5.969)"/>
                </svg>
            </div>
        </div>

        <div class="add-user">
            <form [formGroup]="userForm">
                <!--Name-->
                <input type="text" id="nameInput" class="" formControlName="nameInput" placeholder="{{ 'ADMIN.USER ID' | translate }}" *ngIf="popUpFunction != 'DELETE'">          
            </form>
            <app-demo-button text="{{ 'ADMIN.ADD' | translate}}" color="secondary" type="btn-small" (clickEvent)="addUser()"></app-demo-button>
        </div>
    </div>

</div>
