<div class="header-container">
    <div class="title">
        <h1>DeMO</h1>
        <h2>Admin</h2>
    </div>
    <div class="menu">
        <a routerLink="/settings/production-lines" routerLinkActive="active-link">{{ 'ADMIN.PRODUCTION LINES' | translate }}</a>
        <a routerLink="/settings/roles-rights" routerLinkActive="active-link">{{ 'ADMIN.ROLES RIGHTS' | translate }}</a>
        <a routerLink="/settings/users-roles" routerLinkActive="active-link">{{ 'ADMIN.USERS ROLES' | translate }}</a>
        <a routerLink="/settings/super-admin" routerLinkActive="active-link" *ngIf="isSuperAdmin">{{ 'ADMIN.SUPER ADMIN' | translate }}</a>
        <span href="" class="link-dropdown">
            <span>{{ 'ADMIN.ADMINISTRATE LISTS' | translate }}</span>
            <div class="dropdown-content">
                <a routerLink="/administrate-lists/agents" routerLinkActive="active-link">{{ 'AGENTS' | translate }}</a>
                <a routerLink="/administrate-lists/functions" routerLinkActive="active-link">{{ 'FUNCTIONS' | translate }}</a>
                <a routerLink="/administrate-lists/tags" routerLinkActive="active-link">{{ 'TAGS' | translate }}</a>
            </div>
        </span>
        <span href="" class="btn-dropdown">
            <svg width="15" height="15" viewBox="0 0 93 98" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M90.6484 95.5299C90.6484 76.2499 93.3384 53.6899 46.3384 53.6899C-0.661621 53.6899 2.02838 76.4999 2.02838 95.5299" stroke-width="7" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M46.3378 51.24C59.935 51.24 70.9578 40.2173 70.9578 26.62C70.9578 13.0227 59.935 2 46.3378 2C32.7405 2 21.7178 13.0227 21.7178 26.62C21.7178 40.2173 32.7405 51.24 46.3378 51.24Z" stroke-width="7" stroke-miterlimit="10"/>
            </svg>
            <span>{{getActiveAccount()?.name}}</span>
            <span>{{ '' | translate }}</span>
            
            <div class="dropdown-content">
                <div class="user">
                    <svg width="20" height="20" viewBox="0 0 93 98" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M90.6484 95.5299C90.6484 76.2499 93.3384 53.6899 46.3384 53.6899C-0.661621 53.6899 2.02838 76.4999 2.02838 95.5299" stroke-width="7" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M46.3378 51.24C59.935 51.24 70.9578 40.2173 70.9578 26.62C70.9578 13.0227 59.935 2 46.3378 2C32.7405 2 21.7178 13.0227 21.7178 26.62C21.7178 40.2173 32.7405 51.24 46.3378 51.24Z" stroke-width="7" stroke-miterlimit="10"/>
                    </svg>
                    <span class="name">{{getActiveAccount()?.name}}</span>
                    <span>{{getActiveAccount()?.username}}</span>
                </div>
                <span class="element log-in" *ngIf="!loginDisplay" (click)="login()">Login</span>
                <span class="element log-out" *ngIf="loginDisplay" (click)="logout()">Logout</span>
            </div>
        </span>

    </div>
</div>

