export class FunctionGroup {
    parentId: number | null = null;
    code = "";
    level1 = "";
    level2: string | null = null;
    level3: string | null = null;
    level4: string | null = null;
    nameEN: string | null = null;
    nameFR: string | null = null;
    nameSV: string | null = null;
    nameDE: string | null = null;
    nameNL: string | null = null;
    namePT: string | null = null;
    disabled = false;
    id = 0;

    constructor() {}
}