<div class="page-container">
    <div class="options-container">
        <mat-select id="factory" [(ngModel)]="selectedFactoryId" (selectionChange)="selectFactory()">
            <mat-option *ngFor="let factory of authorizedFactories" [value]="factory.id">{{factory.name}}</mat-option>
        </mat-select>
    </div>

    <div class="main-container" *ngIf="selectedFactoryId != null">
        <div class="key-container">
            <span>{{ 'ADMIN.AGENTNUMBER' | translate }}</span>
            <span>{{ 'ADMIN.NAME' | translate }}</span>
            <span>{{ 'ADMIN.EMAIL' | translate }}</span>
            <span>{{ 'ADMIN.EMPLOYEEID' | translate }}</span>
            <span></span>
        </div>

        <!-- Inputs -->
        <form [formGroup]="newAgentForm" class="agent-container">
            <span>
                <input type="text" 
                    placeholder="{{'ADMIN.AGENTNUMBER' | translate}}" 
                    formControlName="agentNumberInput"
                    autocomplete="off">
            </span>
            <span>
                <input type="text" 
                    placeholder="{{'ADMIN.NAME' | translate}}" 
                    formControlName="agentNameInput"
                    autocomplete="off">
            </span>
            <span>
                <input type="text" 
                    placeholder="{{'ADMIN.EMAIL' | translate}}" 
                    formControlName="emailInput"
                    autocomplete="off">
            </span>
            <span>
                <input type="text" 
                    placeholder="{{'ADMIN.EMPLOYEEID' | translate}}" 
                    formControlName="employeeIdInput"
                    autocomplete="off">
            </span>
            <div class="button-container">
                <app-demo-button
                text="{{ 'ADMIN.ADD' | translate}}"
                color="tertiary"
                type="btn-small"
                (clickEvent)="createNewAgent()"
                [disable]="!newAgentForm.valid || isSavingData"></app-demo-button>
                <span class="error-message" *ngIf="errorMessage != ''">{{errorMessage | translate}}</span>
            </div>
        </form>

        <div class="agent-container" *ngFor="let agent of agentsList">
            <span>{{agent.agentNumber}}</span>
            @if (isSelected(agent)) {
                <form [formGroup]="updateAgentForm" class="agent-container">
                    <span>
                        <input type="text" 
                            placeholder="{{'ADMIN.NAME' | translate}}" 
                            formControlName="agentNameInput"
                            autocomplete="off">
                    </span>
                    <span>
                        <input type="text" 
                            placeholder="{{'ADMIN.EMAIL' | translate}}" 
                            formControlName="emailInput"
                            autocomplete="off">
                    </span>
                    <span>
                        <input type="text" 
                            placeholder="{{'ADMIN.EMPLOYEEID' | translate}}" 
                            formControlName="employeeIdInput"
                            autocomplete="off">
                    </span>
                </form>
            } @else {
                <span>{{agent.agentName}}</span>
                <span>{{agent.email}}</span>
                <span>{{agent.employeeId}}</span>
            }

            <div class="button-container">
                <app-demo-button *ngIf="!isSelected(agent)" svg="edit" color="secondary" type="btn-icon" (clickEvent)="selectAgent(agent)"></app-demo-button>
                <app-demo-button *ngIf="!isSelected(agent)" svg="delete" color="secondary" type="btn-icon" (clickEvent)="openDeletePopup(agent)"></app-demo-button>

                <app-demo-button *ngIf="isSelected(agent)" svg="save" color="secondary" type="btn-icon" (clickEvent)="modifyAgent()" [disable]="!updateAgentForm.valid || isSavingData"></app-demo-button>
                <app-demo-button *ngIf="isSelected(agent)" svg="close" color="secondary" type="btn-icon" (clickEvent)="unselectAgent()"></app-demo-button>
            </div>
        </div>
    </div>
    
    <div class="popup-container" [ngClass]="{'active': deletePopupOpen}">
        <div class="popup">
            <svg (click)="closeDeletePopup()" class="close-icon" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 28.062 28.062">
                <path class="cls-1" d="M5.469,30.371l24.4-24.4,3.66,3.66-24.4,24.4Z" transform="translate(-5.469 -5.969)"/>
                <path class="cls-1" d="M9.127,5.97l24.407,24.4-3.66,3.661L5.467,9.631Z" transform="translate(-5.469 -5.969)"/>
            </svg>

            <span class="title">{{'ADMIN.DELETE AGENT' | translate}}</span>
            
            <span class="text">{{'ADMIN.DELETE TEXT' | translate}}<br>{{ 'ADMIN.CONFIRM' | translate }}</span>

            <app-demo-button text="{{ 'ADMIN.DELETE' | translate }}" color="tertiary" (clickEvent)="deleteAgent()"></app-demo-button>
        </div>
    </div>
</div>

<div class="spinner-container" *ngIf="isSavingData">
    <mat-spinner></mat-spinner>
</div>