<div class="page-container">
    <div class="options-container">
        <mat-select id="factory" [(ngModel)]="selectedFactoryId" (selectionChange)="selectFactory()">
            <mat-option *ngFor="let factory of authorizedFactories" [value]="factory.id">{{factory.name}}</mat-option>
        </mat-select>
        <mat-select id="line" [(ngModel)]="selectedLineId" (selectionChange)="selectLine()">
            <mat-option *ngFor="let line of authorizedLines" [value]="line.id">{{line.name}}</mat-option>
        </mat-select>
    </div>

    <div class="main-container">
        <app-tree-data id="functionField"
                        [functionsList]="functionsList"
                        [currentSelectedFunction]="selectedFunction"
                        (toggleEnabledEvent)="toggleEnabled($event)"
                        (selectEvent)="selectFunction($event)"
                        (addEvent)="openAddFunctionPopup($event)"
                        (editEvent)="openEditFunctionPopup($event)">
        </app-tree-data>

        <div class="lines-container" *ngIf="selectedFunction != null">
            <div class="factory-container" *ngFor="let factory of authorizedFactories">
                <div class="line-container" *ngIf="factory.productionLines.length > 0">
                    <div class="checkbox-container">
                        <input type="checkbox" [id]="'checkbox-' + factory.id + '-all-lines'" [checked]="areAllLinesChecked(factory.id!)" (change)="onCheckAllLinesChange(factory.id!, $event)">
                        <label [for]="'checkbox-' + factory.id + '-all-lines'">{{factory.name}} - {{"ALL LINES" | translate}}</label>
                    </div>
                </div>
                <div class="line-container" *ngFor="let line of factory.productionLines">
                    <div class="checkbox-container">
                        <input type="checkbox" [id]="'checkbox-' + line.id" [checked]="linkedLines.has(line.id!)" (change)="onCheckChange(line.id!, $event)">
                        <label [for]="'checkbox-' + line.id">{{factory.name}} - {{line.name}}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-tree-element-popup [isPopupOpen]="hasFunctionToChange"
                        [selectedFunction]="functionToModify"
                        [selectedLineId]="selectedLineId ?? -2"
                        [parentId]="selectedParentId"
                        (closePopupEvent)="closeFunctionPopup($event)">
</app-tree-element-popup>

<div class="spinner-container" *ngIf="isSavingData">
    <mat-spinner></mat-spinner>
</div>