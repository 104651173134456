import { HttpClient } from '@angular/common/http';
import { Component, HostListener } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Agent } from 'src/app/models/agent.model';
import { FactoryView } from 'src/app/models/factoryViewModel.model';
import { noWhitespaceValidator } from 'src/app/validators/no-whitespace.validator';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-administrate-list-agents',
  templateUrl: './administrate-list-agents.component.html',
  styleUrl: './administrate-list-agents.component.less'
})
export class AdministrateListAgentsComponent {
  apiUrl = environment.API_URL;
  
  @HostListener('window:keydown.escape') escapeEvent() { this.closeDeletePopup(); }
  
  selectedFactoryId:number | null = null;
  authorizedFactories:FactoryView[] = [];
  
  agentsList:Agent[] = [];
    
  selectedAgent:Agent | null = null;
  agentToDelete:Agent | null = null;
  isSavingData = false;
  errorMessage = "";
  deletePopupOpen = false;
  
  newAgentForm = new FormGroup({
    agentNumberInput: new FormControl('', [Validators.required, noWhitespaceValidator()]),
    agentNameInput: new FormControl('', [Validators.required, noWhitespaceValidator()]),
    emailInput: new FormControl(''),
    employeeIdInput: new FormControl('')
  });
  
  updateAgentForm = new FormGroup({
    agentNameInput: new FormControl('', [Validators.required, noWhitespaceValidator()]),
    emailInput: new FormControl(''),
    employeeIdInput: new FormControl('')
  });
  
  constructor(
    private httpClient:HttpClient,
    private translate: TranslateService) {
  }

  ngOnInit() {
    this.getAuthorizedFactories(2);
  }

  selectFactory() {
    this.getAgentsList(this.selectedFactoryId);
  }

  getAuthorizedFactories(page?:number) {
    this.httpClient.get<FactoryView[]>(`${this.apiUrl}Factory/ByPageType/${page}`).subscribe(
      {
        next: (res) => {
          this.authorizedFactories = res;
          this.selectedFactoryId = res[0].id ?? null;
          this.selectFactory();
        }
      }
    );
  }

  getAgentsList(factoryId:number | null) {
    this.agentsList = [];
    this.selectedAgent = null;
    
    if(factoryId == null || localStorage.getItem('language') == null) {
      return;
    }
    
    this.isSavingData = true;
    this.httpClient.get<Agent[]>(`${this.apiUrl}Agent/ByFactoryId/${factoryId}`).subscribe(
      {
        next: (res) => {
          this.agentsList = res;
          this.isSavingData = false;
        },
        error: (e) => {
          this.isSavingData = false;
        }
      }
    );
  }
  
  createNewAgent() {
    if(this.selectedFactoryId == null) { return; }

    this.errorMessage = "";
    if(this.agentsList.find(x => x.agentNumber === this.newAgentForm.value.agentNumberInput) != null) {
      this.errorMessage = "ALREADY EXIST";
      return;
    }

    const newAgent = new Agent();
    newAgent.factoryId = this.selectedFactoryId;

    newAgent.agentNumber = this.newAgentForm.value.agentNumberInput ?? "";
    newAgent.agentName = this.newAgentForm.value.agentNameInput ?? "";
    newAgent.email = this.newAgentForm.value.emailInput ?? "";
    newAgent.employeeId = this.newAgentForm.value.employeeIdInput ?? "";

    this.isSavingData = true;
    this.httpClient.post<Agent>(`${this.apiUrl}Agent`, newAgent).subscribe(
      {
        next: (res) => {
          this.newAgentForm.reset();
          this.agentsList.push(res);
          this.agentsList.sort((a, b) => { return a.agentNumber.localeCompare(b.agentNumber)});
          this.isSavingData = false;
        },
        error: (e) => {
          this.isSavingData = false;
        }
      }
    );
  }

  modifyAgent() {
    if (this.selectedAgent == null) { return; }

    this.selectedAgent.agentName = this.updateAgentForm.value.agentNameInput ?? "";
    this.selectedAgent.email = this.updateAgentForm.value.emailInput ?? "";
    this.selectedAgent.employeeId = this.updateAgentForm.value.employeeIdInput ?? "";

    this.isSavingData = true;
    this.httpClient.put<Agent>(`${this.apiUrl}Agent`, this.selectedAgent).subscribe(
      {
        next: (res) => {
          const agentIdx = this.agentsList.findIndex(x => x.agentNumber === res.agentNumber);
          if (agentIdx !== -1) {
            this.agentsList[agentIdx] = res;
          }
          this.unselectAgent();
          this.isSavingData = false;
        },
        error: (e) => {
          this.isSavingData = false;
        }
      }
    );
  }

  deleteAgent() {
    if (this.agentToDelete == null) { return; }

    this.isSavingData = true;
    this.httpClient.delete<Agent>(`${this.apiUrl}Agent/${this.agentToDelete.factoryId}/${this.agentToDelete.agentNumber}`).subscribe(
      {
        next: (res) => {
          this.agentsList.splice(this.agentsList.findIndex(x => x.agentNumber === res.agentNumber), 1);
          this.unselectAgent();
          this.closeDeletePopup();
          this.isSavingData = false;
        },
        error: (e) => {
          this.isSavingData = false;
        }
      }
    );
  }

  selectAgent(agent: Agent) {
    this.selectedAgent = {...agent};
    this.updateAgentForm.reset();
    this.updateAgentForm.enable();
    
    this.updateAgentForm.setValue({
      agentNameInput: agent.agentName,
      emailInput: agent.email,
      employeeIdInput: agent.employeeId
    });
  }

  unselectAgent() {
    this.selectedAgent = null;
    this.updateAgentForm.reset();
  }

  isSelected(agent: Agent): boolean {
    return this.selectedAgent?.agentNumber === agent.agentNumber;
  }
    
  openDeletePopup(agent: Agent) {
    this.agentToDelete = agent;
    this.deletePopupOpen = true;
  }
    
  closeDeletePopup() {
    this.agentToDelete = null;
    this.deletePopupOpen = false;
  }
}
